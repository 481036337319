export default {
  'loader': {
    'title': 'websites de verdad'
  },
  'header': {
    title: {
      '1': 'Twob',
      '2': 'Proyectos',
      '3': 'Contacto'
    },
    links: {
      '1': '/',
      '2': '/proyectos',
      '3': '/contacto'
    }
  },
  'home': {
    'title:1': 'Podemos cambiar tu mundo digital ',
    'title:2': 'dandole una perspectiva fresca a tu marca. Hacemos websites de verdad.'
  },
  'homeWho': {
    'subtitle': 'Quienes somos',
    'txt:1': 'Formamos un dúo especializado en diseño y desarrollo web moderno e interfaces de usuario. Creemos en la importancia de los detalles que ves y los que no, y nos gusta pensar que nuestro enfoque personal sobre cómo trabajamos es la razón por lo que te parecerá genial elegirnos.',
    'txt:2': 'Ahora mismo estamos terminando de construir nuestro nuevo sitio web, mientras tanto, puedes conocer',
    'txt:3': 'nuestro trabajo',
    'txt:4': ' y lo que hacemos o puedes ',
    'txt:5': 'contactarnos',
    'txt:6': ' para hablar sobre como comenzar con tu proyecto.',
    'txt:7': 'Somos creadores de sitios web, en nuestros proyectos nos enfocamos principalmente en dar una imagen moderna y atrevida. Trabajamos para clientes que quieran dejar atrás los convencionalismos, que no tengan miedo de evolucionar y que esten interesados en diferenciarse de su competencia.',
    'txt:8': 'Con sede en Barcelona y Buenos Aires, trabajamos en todo tipo de proyectos a nivel mundial, tanto en marcas consolidadas como en nuevas iniciativas. El tamaño no importa, nuestra misión es resolver problemas reales para personas reales, como tú 🙌🔥'
  },
  'homeWhat': {
    'subtitle': 'Que hacemos',
    'txt': 'Si estás cansado de leer las mismas descripciones de empresas en las que todas aseguran ser diferentes pero en realidad no lo son. ¡Estupendo! Nosotros también, por eso pasemos directo a la parte en donde te quedará más claro en qué y como podremos echarte un cable.'
  },
  'contactFooter': {
    'subtitle': 'Contacto',
    'title:1': 'Inicia ',
    'title:2': 'tu proyecto',
    'marquee': 'Sabemos lo que quieres. Sabemos lo que quieres. Sabemos lo que quieres.',
    'txt': 'Siéntete libre de comunicarte, somos muy amigables y siempre estamos abiertos a conversar sobre nuevos proyectos, oportunidades o ideas creativas para ser parte de tu equipo.'
  },
  'contactForm': {
    'your:name': 'Tu nombre *',
    'name:required': 'Tu nombre es requerido',
    'your:brand': 'Nombre de tu marca',
    'your:email': 'Tu email *',
    'email:required': 'Tu email es requerido',
    'email:valid': 'Por favor ingresa un email válido',
    'your:phone': 'Teléfono',
    'select:how': 'Como nos encontrastes?',
    'select:optionsWhere':
    {
      options:
      [
        'Hice una búsqueda en la web',
        'Os vi en las redes sociales',
        'Lei un articulo sobre vosotros',
        'Tengo una recomendación',
        'Otras'
      ]
    },
    'select:time': 'Tiempo estimado',
    'select:optionsTimneline':
    {
      options:
      [
        '1-2 semanas',
        '1 mes',
        '2 meses',
        '3 meses',
        '+ 3 meses'
      ]
    },
    'select:budget': 'Cual es tu presupuesto?',
    'select:optionsBudget':
    {
      options:
      [
        'Aún no lo se',
        '800 - 1500€',
        '1500 - 3000€',
        '3000 - 5000€',
        '5000 - 10000€',
        '10000€ +'
      ]
    },
    'title:services': 'En qué podemos ayudarte?',
    'service:dd': 'Diseño y desarrollo web',
    'your:message': 'Detalles sobre su proyecto:',
    'submit:button': 'Compartelo con nosotros',
    'privacy:1': 'Al hacer click en el botón, aceptas la recopilación y el procesamiento de mis datos personales como se describe en la',
    'privacy:2': 'Política de privacidad'
  },
  'contactThanks': {
    'message:1': 'Progreso en marcha',
    'message:2': 'Tus datos fueron enviados, nos comunicaremos contigo lo antes posible. Mientras tanto, puedes seguir explorando nuestro sitio web.'
  },
  'contactError': {
    'message:1': 'Error al enviar el formulario',
    'message:2': 'Ups! Algo a fallado, intentalo de nuevo en un momento.'
  },
  'contactPage': {
    'subtitle': 'inicia tu proyecto',
    'title:1': 'Tienes ',
    'title:2': 'alguna idea en mente?',
    'txt:1': 'Estamos aquí para ayudarte a hacerlas realidad, sin importar cuán grandes o pequeñas sean, simples o complicadas. Seguramente encontraremos la solución adecuada para ti y será un placer poder acompañarte durante todo el proceso.',
    'txt:2': 'Tómate unos minutos y completa nuestro planificador de proyectos para comenzar con anticipación tu desarrollo. Lo revisaremos y te contactaremos dentro de uno o dos días hábiles respondiendo a tu consulta y para comentar los pasos a seguir.',
    'footer': 'Prefieres email o teléfono?'
  },
  'cookieAdvice': {
    'txt:1': 'Hacemos uso de las cookies para mejorar tu experiencia de navegación. Si continúas utilizando nuestro sitio web aceptas la ',
    'txt:2': 'política de cookies.',
    'txt:3': 'Sí, me gustan!'
  },
  'footerBottom': {
    'txt:1': 'Website completo muy pronto'
  },
  'onBlur': {
    'title': '🤘 Vuelve pronto.'
  },
  'onFocus': {
    'title': 'Twobrothers | Estudio de diseño y desarrollo web moderno | Websites de verdad'
  },
  'routeText': {
    'title:1': 'Código con mente',
    'title:2': 'Diseño con alma',
    'title:3': 'Websites de verdad',
    'title:4': 'Temitas legales',
    'title:5': 'Nada por aquí'
  },
  'orientationTxt': {
    'txt': 'Por favor use la orientación vertical'
  },
  'projects': {
    info: {
      '1': 'Diseño Desarrollo Nuxt js Prismic Netlify',
      '2': 'Diseño Desarrollo Nuxt js Prismic Netlify',
      '3': 'Diseño Desarrollo WP Theme Sage Bedrock Woocommerce',
      '4': 'Coming Soon WP Theme Sage Bedrock',
      '5': 'Diseño Desarrollo Ajax Portfolio SEO WP Custom',
      '6': 'Diseño Desarrollo Custom Wordpress E-commerce',
      '7': 'Diseño Desarrollo Wordpress Theme Portfolio',
      '8': 'Diseño Desarrollo Wordpress Theme Portfolio',
      '9': 'Wordpress Theme Portfolio'
    }
  },
  'services': {
    title: {
      '1': 'Diseño web',
      '2': 'Custom Static Websites',
      '3': 'Desarrollo web',
      '4': 'Customización de temas',
      '5': 'E-commerce',
      '6': 'Web apps',
      '7': 'Wordpress',
      '8': 'Hosting and SSL',
      '9': 'Squarespace',
      '10': 'SEO & Social',
      '11': 'Shopify',
      '12': 'Mantenimiento & Soporte'
    },
    description: {
      '1': 'Un sitio web bien diseñado debe transmitir la personalidad de tu marca, producto o servicio de una manera simple pero completa. Tus usuarios suelen ser impacientes y probablemente también estén distraídos. Lo mantendremos simple y hermoso, divertido y funcional. Una estética limpia respaldada por un concepto sólido es lo que siempre buscaremos.',
      '2': 'Un proyecto basado en un CMS como Wordpress no siempre es necesario o es la mejor solución. A veces, un sitio web personalizado es lo que mejor se adapta a ciertos proyectos. Podremos ayudarte a determinar los detalles dentro de estos escenarios.',
      '3': 'Tenemos curiosidad sobre las nuevas tecnologías emergentes y continuamos evolucionando. Hacemos productos digitales a través de un enfoque centrado en el ser humano. No solo creemos en las grandes ideas, sino también en la perfecta ejecución de ellas. Escribimos código inteligente utilizando la tecnología más reciente para crear los mejores productos digitales.',
      '4': '¿Necesitas algunos cambios en una plantilla o tema dentro de una plataforma CMS donde realizaras tu web? Podemos ayudarte a analizar las posibilidades de personalizar o agregar nuevas características o funcionalidades a la situación actual.',
      '5': 'Las tiendas online se pueden construir de diferentes maneras, utilizando plataformas como WordPress, Shopify y Squarespace. En twob creamos tiendas de diversa complejidad, desde las más simples hasta las más complejas, ofreciendo la máxima simplicidad tanto como para el usuario final como para el administrador del sitio web.',
      '6': 'Damos soluciones de última generación, nos centramos en desarrollar web apps personalizadas basadas en JavaScript con VueJs y Nuxt Js. Algunas caracteristicas de esta tecnología de futuro son que el código no se volverá obsoleto y sera fácil de desarrollar, es rapido y fluido, tendrás la misma experiencia en todos los dispositivos y podrás navegar offline.',
      '7': 'Ya sea para una landing page, un sitio web personal, un blog o un portfolio, te ayudaremos a obtener lo mejor de WordPress y a utilizar sus funcionalidades. También podremos ayudarte a diseñar y desarrollar tu tienda online usando la plataforma WordPress + Woocommerce y personalizar sus características en función de las necesidades de tu negocio.',
      '8': 'El primer año te lo ofrecemos sin cargos,  y el certificado SSL gratis para siempre. Si bien hay muchas opciones disponibles, creemos que nuestro socio de alojamiento es el futuro de los servidores. Podríamos ponernos un poco nerd al respecto, pero todo lo que realmente importa es que tu sitio web será súper rápido y confiable.',
      '9': 'Squarespace puede usarse para sitios web regulares, tiendas online o blogs. Podemos integrar reservas de citas, cuentas de redes sociales, calendarios, videos, galerías o servicios como Foursquare. Las opciones son infinitas y tu sitio web podrá crecer y cambiar fácilmente a medida que tu negocio lo haga.',
      '10': 'Te proporcionaremos las herramientas esenciales y te ayudaremos a comenzar con las tareas necesarias para tener éxito en el ámbito del SEO. Dependiendo de la plataforma en la que se construya el proyecto, podremos instalar las diversas herramientas, así como proporcionar información para que te centres en tus necesidades de SEO y analíticas.',
      '11': 'Diseñamos, desarrollamos, desplegamos y damos soporte a sitios web de comercio electrónico a pequeña y gran escala usando Shopify. Esto incluye nuevos desarrollos completos, migraciones de plataformas, así como proyectos de desarrollo a medida.',
      '12': 'Ofrecemos servicios para mantener tu sitio web actualizado y en funcionamiento monitoreando las actualizaciones, el rendimiento y la velocidad. Si deseas soporte adicional después del lanzamiento o si surge algo nuevo como integraciones, componentes u otras cosas que podrían estar fuera de tu alcance, estaremos listos para intervenir.'
    }
  },
  'errorPage': {
    'title': 'Esa página no exite.',
    'txt:1': 'Probablemente es algo que hemos estropeado, ¿o tal vez has escrito mal la URL? De cualquier manera puedes',
    'txt:2': 'empezar de nuevo.'
  },
  'privacyPage': {
    'subtitle': 'tus datos',
    'title:1': 'Política ',
    'title:2': 'de privacidad',
    'inner:title:1': 'Nuestro compromiso',
    'inner:txt:1': 'La confidencialidad y la seguridad son nuestros valores primordiales y, en consecuencia, asumimos el compromiso de garantizar la privacidad del Usuario en todo momento y de no recabar información innecesaria. A continuación, le proporcionamos toda la información necesaria sobre nuestra Política de Privacidad en relación con los datos personales que recabamos.',
    'inner:title:2': '1.1 Responsabilidad',
    'inner:txt:2': 'Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualquier otra disposición legal que fuera de aplicación.',
    'inner:txt:3': 'El prestador se exime de cualquier tipo de responsabilidad derivada de la información publicada en su sitio web y por la falta de disponibilidad (caídas) del sitio el cual efectuará además paradas periódicas por mantenimientos técnicos. Además, el prestador se reserva el derecho a modificar cualquier tipo de información que pudiera aparecer en el sitio web, sin que exista obligación de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendiéndose como suficiente con la publicación en el sitio web del prestador.',
    'inner:title:3': '1.2 Uso de cookies',
    'inner:txt:4': 'El sitio web del prestador puede utilizar cookies (pequeños archivos de texto que el servidor envía al ordenador de quien accede a la página). Se trata de una técnica usada de manera habitual en Internet para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualización del sitio. Las cookies utilizadas en el sitio web tienen, en todo caso, carácter temporal con la única finalidad de hacer más eficaz su transmisión ulterior y desaparecen al terminar la sesión del usuario. En ningún caso se utilizarán las cookies para recoger información de carácter personal.',
    'inner:txt:5': 'Desde el sitio web del cliente es posible que se redirija a contenidos de terceros sitios web.',
    'inner:txt:6': 'Dado que el prestador no puede controlar siempre los contenidos introducidos por los terceros en sus sitios web, éste no asume ningún tipo de responsabilidad respecto a dichos contenidos. En todo caso, el prestador se compromete a la retirada inmediata de cualquier contenido que pudiera contravenir la legislación nacional o internacional, la moral o el orden público, procediendo a la retirada inmediata de la redirección a dicho sitio web, poniendo en conocimiento de las autoridades competentes el contenido en cuestión.',
    'inner:txt:7': 'Para ampliar más información sobre la política de cookies que utilizamos, puede consultar la página política de cookies haciendo click aquí.',
    'inner:title:4': '1.3 Protección de datos personales',
    'inner:txt:8': 'El prestador cumple con la normativa española de protección de datos de carácter personal, y garantiza el cumplimiento íntegro de las obligaciones dispuestas la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la LOPD y demás normativa vigente en cada momento, y vela por garantizar un correcto uso y tratamiento de los datos personales del usuario.',
    'inner:title:5': '1.4 Política anti-spam',
    'inner:txt:9': 'El prestador se declara completamente en contra del envío de comunicaciones comerciales no solicitadas y a cualquier tipo de conducta o manifestación conocida como “spam”, asimismo se declara comprometido con la lucha contra este tipo de prácticas abusivas.',
    'inner:txt:10': 'Por tanto, el prestador garantiza al usuario a que bajo ningún concepto los datos personales recogidos en el sitio web serán cedidos, compartidos, transferidos, ni vendidos a ningún tercero, si su consentimiento expreso.',
    'inner:txt:11': 'Por las mismas razones, el proceso de registro no debe ser utilizado para inscribir direcciones de correo de personas ajenas sin el consentimiento expreso de las personas afectadas.',
    'inner:txt:12': 'twobstudio.com implementa como contramedida a esta práctica una lista de correo de tipo double opt-in que necesita la confirmación explicita por parte del titular de la cuenta de correo electrónico indicada como dirección de suscripción, antes de recibir comunicaciones por correo electrónico.',
    'inner:txt:13': 'En el caso de que aun así un usuario reciba comunicaciones de este sitio web sin haberse registrado, o sin haber dado su consentimiento expreso a dicho registro, puede cancelar la suscripción desde los enlaces que se proporcionan en la propia comunicación.',
    'inner:txt:14': 'Además, el usuario puede ponerse en contacto con nosotros a través del formulario de contacto que se mantiene en el sitio web, tanto para comunicar lo sucedido como para solicitar la eliminación inmediata de sus datos de nuestro sistema.',
    'inner:title:6': '1.5 Alojamiento de datos',
    'inner:txt:15': 'Por razones técnicas y de calidad de servicio, la prestación del servicio de suscripción por correo electrónico y envío de newsletter se efectúa desde las instalaciones de la empresa MailChimp (política de privacidad). Empresa de origen estadounidense con instalaciones que se ubican en este mismo país adherida a los principios de “Puerto Seguro” (Safe Harbor), de acuerdo con la Decisión 2000/520/CE de la Comisión de 26 de julio de 2000 lo que las convierte entidades con un nivel adecuado de protección a efectos de la LOPD.',
    'inner:title:7': '1.6 Google analytics',
    'inner:txt:16': 'Google Analytics es un servicio análisis de datos estadísticas prestado por la empresa Google (política de privacidad). twobstudio.com utiliza este servicio para realizar un seguimiento de las estadísticas de uso del mismo.',
    'inner:txt:17': 'Google Analytics utiliza cookies para ayudar al sitio web a analizar datos estadísticos sobre el uso del mismo (número de visitas totales, páginas más vistas, etc.). La información que genera la cookie (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.',
    'inner:txt:18': 'Google usará esta información por cuenta nuestra con el propósito de generar informaciones estadísticas sobre el uso de twobstudio.com, Google no asociará su dirección IP con ningún otro dato del que disponga Google. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google.',
    'inner:txt:19': 'Puede Usted rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, de hacerlo, limitará la plena funcionabilidad de twobstudio.com. Al utilizar este sitio web, da su consentimiento al tratamiento de información por Google en la forma y para los fines arriba indicados.',
    'inner:title:8': '1.7 Consentimiento al tratamiento de los datos personales',
    'inner:txt:20': 'En el marco de sus actividades, twobstudio.com dispone de la posibilidad de registro de usuarios para el envío de comunicaciones por correo electrónico, efectuar comentarios en el blog y enviar mensajes a través del formulario de contacto.',
    'inner:txt:21': 'El usuario mediante los actos de proceso de compra (si lo hay), la suscripción al blog, la realización comentarios o el formulario de contacto estará dando su consentimiento expreso al tratamiento de los personales proporcionados según lo dispuesto en el artículo 6 de la LOPD. El usuario podrá ejercer sus derechos en los términos dispuestos por el artículo 5 de la LOPD.',
    'inner:txt:22': 'Estos mismos actos implican asimismo el consentimiento expreso del usuario a la transferencia internacional de datos que se produce en términos de la LOPD debido a la ubicación física de las instalaciones de proveedores arriba mencionados.',
    'inner:txt:23': 'Los datos de carácter personal solicitados en estas actividades, quedarán incorporados a un fichero cuya finalidad es la comunicación de novedades relativas al sitio web de twobstudio.com actuando como responsable del fichero el prestador. Los campos marcados con asterisco son de cumplimentación obligatoria, siendo imposible realizar la finalidad expresada si no se aportan estos datos. Queda igualmente informado de la posibilidad de ejercitar los derechos que se indican en el apartado relativo a los Derechos del usuario.',
    'inner:title:9': '1.8 Derechos del usuario',
    'inner:txt:24': 'De conformidad con lo establecido en el artículo 5 de la LOPD, se informa al usuario que la finalidad exclusiva de la base de datos de registro es el envío de información sobre novedades relacionadas con el sitio web twobstudio.com así como la formalización del proceso de compra (en caso de existir) y los comunicados derivados de ese mismo proceso de compra. Únicamente los titulares tendrán acceso a sus datos, y bajo ningún concepto, estos datos serán cedidos, compartidos, transferidos, ni vendidos a ningún tercero.',
    'inner:txt:25': 'De acuerdo con lo dispuesto en la LOPD, el usuario en cualquier momento podrá ejercitar sus derechos de acceso, rectificación, cancelación, y oposición ante el prestador.',
    'inner:txt:26': 'Para facilitar el ejercicio de estos derechos se facilita en todas las comunicaciones un enlace de solicitud de baja que redundará en la eliminación inmediata de los datos personales del usuario de nuestra base de datos.',
    'inner:title:10': '1.9 Propiedad intelectual y usos de los contenidos',
    'inner:txt:27': 'El sitio web twobstudio.com, incluyendo a título enunciativo, pero no limitativo su programación, edición, compilación y demás elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos son propiedad del prestador o en su caso dispone de licencia o autorización expresa por parte de los autores.',
    'inner:txt:28': 'Cualquier uso no autorizado previamente por parte del prestador será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.',
    'inner:txt:29': 'Los diseños, logotipos, texto y/o gráficos ajenos al prestador y que pudieran aparecer en el sitio web, pertenecen a sus respectivos propietarios, siendo ellos mismos responsables de cualquier posible controversia que pudiera suscitarse respecto a los mismos. En todo caso, el prestador cuenta con la autorización expresa y previa por parte de los mismos.',
    'inner:txt:30': 'Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, puede hacerlo a través del formulario de contacto que se mantiene en el sitio web.',
    'inner:title:11': '1.10 Ley aplicable y jurisdicción',
    'inner:txt:31': 'Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de las actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten expresamente las partes.'
  },
  'cookiesPage': {
    'subtitle': 'cookies y twob',
    'title:1': 'Política ',
    'title:2': 'de cookies',
    'inner:title:1': '1.1 Qué son las cookies y por que las utilizamos?',
    'inner:txt:1': 'Una cookie es un fichero que se descarga en el dispositivo del usuario al acceder a determinadas páginas web para almacenar y recuperar información sobre la navegación que se efectúa desde dicho equipo.',
    'inner:txt:2': 'Las cookies permiten a dicha web, entre otras cosas, almacenar y recuperar información sobre las decisiones y hábitos del usuario. En twobstudio.com las utilizamos para personalizar la experiencia dentro de la web, identificar y recordar al usuario registrado y facilitar la navegación.',
    'inner:txt:3': 'Es importante destacar que el uso de cookies no proporciona datos personales del usuario, que permanece anónimo.',
    'inner:txt:4': 'El usuario puede configurar su navegador para no aceptar el uso de cookies, en cuyo caso la personalización de la experiencia no se aplicaría, aunque sí podrá seguir accediendo a los contenidos de nuestras webs con normalidad.',
    'inner:txt:5': 'En la página de Wikipedia ',
    'inner:txt:6': 'es posible obtener más información acerca de qué con las cookies y cómo funcionan.',
    'inner:title:2': '1.2 Qué tipo de cookies utiliza nuestra web',
    'inner:txt:7': 'Las cookies, en función de su permanencia, pueden dividirse en cookies de sesión o permanentes. Las primeras expiran cuando el usuario cierra el navegador. Las segundas expiran en función cuando se cumpla el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga identificado en las webs) o bien cuando se borran manualmente',
    'inner:txt:8': 'Adicionalmente, en función de su objetivo, las cookies pueden clasificarse de la siguiente forma:',
    'inner:txt:9': 'Cookies de rendimiento: este tipo de cookie recuerda sus preferencias para las herramientas que se encuentran en los servicios, por lo que no tiene que volver a configurar el servicio cada vez que usted visita. A modo de ejemplo, en esta tipología se incluyen:',
    'inner:txt:10': 'Ajustes de volumen de reproductores de vídeo o sonido.',
    'inner:txt:11': 'Las velocidades de transmisión de vídeo que sean compatibles con su navegador.',
    'inner:txt:12': 'Cookies de geolocalización: estas cookies son utilizadas para averiguar en qué país se encuentra cuando se solicita una web o servicio. Esta cookie es totalmente anónima, y sólo se utiliza para ayudar a orientar el contenido a su ubicación.',
    'inner:txt:13': 'Cookies de registro: las cookies de registro se generan una vez que el Usuario se ha registrado o posteriormente ha abierto su sesión, y se utilizan para identificarle en las web y servicios con los siguientes objetivos:',
    'inner:txt:14': 'Mantener al usuario identificado de forma que, si cierra un servicio o web, el navegador o el ordenador y en otro momento u otro día vuelve a entrar en dicho servicio o web, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse. Esta funcionalidad se puede suprimir si el usuario pulsa la funcionalidad “Salir”, de forma que esta cookie se elimina y la próxima vez que entre en la web el usuario tendrá que iniciar sesión para estar identificado.',
    'inner:txt:15': 'Comprobar si el usuario está autorizado para acceder a ciertos servicios, por ejemplo, para participar en un concurso.',
    'inner:txt:16': 'Adicionalmente, algunos servicios pueden utilizar conectores de terceros tales como Facebook, Twitter o Google. Cuando el usuario se registra en un servicio con credenciales de una red social o sistema de identificación de terceros, autoriza al mismo a guardar una cookie persistente que recuerda su identidad y le garantiza acceso a la web hasta que expira. El usuario puede borrar esta cookie y revocar el acceso a la web mediante redes sociales o sistema de identificación de terceros actualizando sus preferencias en la red social que específica.',
    'inner:txt:17': 'Cookies de analítica: Cada vez que un Usuario visita una Web o Servicio, una herramienta de un proveedor externo (Google Analytics, comScore y similares) genera una cookie analítica en el dispositivo del usuario. Esta cookie que sólo se genera en la visita, servirá en próximas visitas a los servicios de twobstudio.com para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:',
    'inner:txt:18': 'Permitir la identificación anónima de los usuarios navegantes a través de la “cookie” (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo.',
    'inner:txt:19': 'Identificar de forma anónima los contenidos más visitados y por lo tanto más atractivos para los usuarios.',
    'inner:txt:20': 'Saber si el usuario que está accediendo es nuevo o repite visita.',
    'inner:txt:21': 'Importante: Salvo que el Usuario decida registrarse, la cookie nunca irá asociada a ningún dato de carácter personal que pueda identificarle. Dichas cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la optimización de la experiencia de los usuarios en el sitio.',
    'inner:txt:22': 'Cómo deshabilitar las cookies en el navegador. La mayoría de navegadores actualmente permiten al usuario configurar si desean aceptar cookies y cuáles de ellas. Estos ajustes normalmente se encuentran en las ‘opciones’ o ‘Preferencias’ del menú de su navegador.',
    'inner:txt:23': 'Estas son las instrucciones para configurar las cookies en los principales navegadores:',
    'inner:txt:24': 'Chrome: Configuración -> Mostrar opciones avanzadas -> Privacidad -> Configuración de contenido. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.',
    'inner:txt:25': 'Firefox: Herramientas -> Opciones -> Privacidad -> Historial -> Configuración Personalizada. Para más información, puede consultar el soporte de Mozilla o la Ayuda del navegador.',
    'inner:txt:26': 'Internet Explorer: Herramientas -> Opciones de Internet -> Privacidad -> Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.',
    'inner:txt:27': 'Safari: Preferencias -> Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.'
  },
  'marquee': {
    'txt': 'Hacemos sitios web, de verdad. Hacemos sitios web, de verdad. Hacemos sitios web, de verdad.'
  },
  'seoHome': {
    'title': 'Twobrothers | Estudio de diseño y desarrollo web moderno | Websites de verdad',
    'description': 'Twobrothers – Somos una agencia digital en Barcelona y Buenos Aires. Un equipo de expertos en diseño y desarrollo web moderno, podemos crear tu sitio web, landing page, tienda online y mucho más.'
  },
  'seoProjects': {
    'title': 'Twobrothers | Descubre nuestros proyectos de diseño y desarrollo web',
    'description': 'En nuestro porfolio puedes consultar nuestros proyectos más destacados. Encuentra ejemplos de diseño web o desarrollo web'
  },
  'seoContact': {
    'title': 'Twobrothers | Contactar con nuestro estudio de diseño y desarrollo web | Comienza tu proyecto',
    'description': '¡Hablemos, o incluso nos vemos! Ponte en contacto con nosotros para concertar una reunión, llámanos: +34 633 282 293'
  },
  'notFound': {
    'title': 'Twobrothers | Pagina no encontrada',
    'description': 'Pagina no encontrada'
  },
  language_picker_helper: 'Idioma',
  languages: {
    spanish: 'Es',
    english: 'En'
  }
}
