<template>
  <div class="footer--section" data-scroll-section>
    <div class="twob--columns">
      <div class="twob--column is-12">
        <span class="subtitle scroll--animation" data-scroll>{{ $t('contactFooter.subtitle') }}</span>
        <h1 class="scroll--animation" data-scroll>
          <router-link
            to="/contacto/"
            class="inner--link"
            @mouseover.native="isOver"
            @mouseleave.native="isOut">
            <span class="visible--link"><span class="invisible--txt">{{ $t('contactFooter.title:1') }}</span>{{ $t('contactFooter.title:2') }}</span>
            <div class="marquee-wrapper small--link">
              <span>{{ $t('contactFooter.marquee') }}</span>
              <span>{{ $t('contactFooter.marquee') }}</span>
            </div>
          </router-link>
        </h1>
      </div>
    </div>
    <div id="trigger" class="twob--columns align--right">
      <div class="twob--column is-8">
        <div class="twob--txt footer--txt">
          <p class="no--margin__bottom scroll--animation" data-scroll>{{ $t('contactFooter.txt') }}</p>
        </div>
        <div class="twob--txt footer--txt">
          <div class="twob--columns">
            <div class="twob--column is-6 is--footer">
              <span class="subtitle subtitle--footer scroll--animation" data-scroll>Barcelona</span>
              <a
                class="dot footer--link scroll--animation"
                href="mailto:cristian@twobstudio.com"
                @mouseover="isOver"
                @mouseleave="isOut"
                data-scroll>cristian@twobstudio.com</a>
              <a
                class="dot footer--link scroll--animation"
                href="tel:0034633282293"
                @mouseover="isOver"
                @mouseleave="isOut"
                data-scroll>+34 633 282 293</a>
            </div>
            <div class="twob--column is-6 is--footer">
              <span class="subtitle subtitle--footer scroll--animation" data-scroll>Buenos Aires</span>
              <a
                class="dot footer--link scroll--animation"
                href="mailto:javier@twobstudio.com"
                @mouseover="isOver"
                @mouseleave="isOut"
                data-scroll>javier@twobstudio.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'

export default {
  mixins: [
    cursorMixin
  ]
}
</script>

<style lang="scss">
.footer--txt .subtitle--footer {
  margin-bottom: 2.8vw;
  @include respond-to("tablet-portrait") { margin-bottom: 1.2vw; }
}
.footer--txt {
  margin-top: 8vw;
  @include respond-to("tablet-portrait") { margin-top: 4vw; }
}
.footer--link {
  display: block;
  letter-spacing: 0;
  font-size: 2.6vw;
  line-height: 2.1;
  color: rgba(255, 255, 255, 0.9);
  &:hover { animation: color .4s infinite; }
  @include respond-to("tablet-portrait") { font-size: 1.56vw; letter-spacing: -.02rem; }
  @include respond-to("desktop-medium") { font-size: 1.36vw; }
}
</style>
