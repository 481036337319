<template>
  <div class="services--container">
    <ul
      :key="column.id"
      v-for="column in columns"
      class="twob--services__list"
    >
      <li
        :key="key"
        v-for="{ title, description, key } in column"
        @click="accordionToggle(key)"
        :class="{ 'state--open': isOpened[key-1] }"
        @mouseover="isOver"
        @mouseleave="isOut">
        <div :class="[`key--${key}`]" class="services--item scroll--animation" data-scroll>
          <p>{{ title }}</p>
        </div>
        <transition name="twob--services__accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <div class="services--item__content" v-if="isOpened[key-1]">
            <span>{{ description }}</span>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'

export default {
  mixins: [cursorMixin],
  data () {
    return {
      cols: 2,
      isOpened: [ false, false, false, false, false, false, false, false, false, false, false, false ]
    }
  },
  computed: {
    items () {
      return [
        { key: 1, title: this.$t('services.title.1'), description: this.$t('services.description.1') },
        { key: 2, title: this.$t('services.title.3'), description: this.$t('services.description.3') },
        { key: 3, title: this.$t('services.title.5'), description: this.$t('services.description.5') },
        { key: 4, title: this.$t('services.title.7'), description: this.$t('services.description.7') },
        { key: 5, title: this.$t('services.title.9'), description: this.$t('services.description.9') },
        { key: 6, title: this.$t('services.title.11'), description: this.$t('services.description.11') },
        { key: 7, title: this.$t('services.title.2'), description: this.$t('services.description.2') },
        { key: 8, title: this.$t('services.title.4'), description: this.$t('services.description.4') },
        { key: 9, title: this.$t('services.title.6'), description: this.$t('services.description.6') },
        { key: 10, title: this.$t('services.title.8'), description: this.$t('services.description.8') },
        { key: 11, title: this.$t('services.title.10'), description: this.$t('services.description.10') },
        { key: 12, title: this.$t('services.title.12'), description: this.$t('services.description.12') }
      ]
    },
    columns () {
      let columns = []
      let mid = Math.ceil(this.items.length / this.cols)
      for (let col = 0; col < this.cols; col++) {
        columns.push(this.items.slice(col * mid, col * mid + mid))
      }
      return columns
    }
  },
  methods: {
    accordionToggle (key) {
      // this.isOpened[key] = !this.isOpened[key]
      // this.$set(this.isOpened, key-1, !this.isOpened[key-1])
      this.isOpened = this.isOpened.map((i, idx) => {
        if (idx === key - 1) {
          return !i
        }
        return false
      })
      this.$emit('scrollChange')
    },
    beforeEnter (el) {
      el.style.height = '0'
    },
    enter (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave (el) {
      el.style.height = '0'
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}
.twob--services__list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10.4vw;
  @include respond-to("tablet-portrait") { margin-top: 6.4vw; }
  li {
    display: block;
    cursor: pointer;
    &.state--open p {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  .services--item {
    margin-bottom: 3.2vw;
    display: inline-block;
    @include respond-to("tablet-portrait") { margin-bottom: 2.4vw; }
    @include respond-to("desktop") { margin-bottom: 1.7vw; }
    @include respond-to("desktop-extra-large") { margin-bottom: 1.4vw; }
    &.key--9 p:after {
      content: "💥";
      position: absolute;
      right: -19px;
      top: 62%;
      transform: translateY(-50%);
      @include respond-to("desktop") {
        right: -29px;
        top: 60%;
      }
      @include respond-to("desktop-large") {
        right: -30px;
        top: 55%;
      }
      @include respond-to("desktop-extra-large") {
        right: -33px;
        top: 58%;
      }
    }
    &:hover p { color: rgba(255, 255, 255, 0.9); }
  }
  p {
    position: relative;
    display: inline-block;
    transition: color .35s ease;
    line-height: 1.5;
    margin: 0;
  }
}
.services--item__content {
  overflow: hidden;
  padding-right: 8%;
  transition: height 0.3s ease-in-out;
  @include respond-to("desktop") { padding-right: 12%; }
  span {
    font-size: 11px;
    color: #888888;
    margin-bottom: 2rem;
    line-height: 1.8;
    display: block;
    @include respond-to("tablet-portrait") { font-size: 11px; margin-bottom: 1.5rem; line-height: 2.1; }
    @include respond-to("desktop") { font-size: 12px; margin-bottom: 2.5rem; }
    @include respond-to("desktop-medium") { font-size: 14px; }
    @include respond-to("desktop-large") { font-size: 15px; margin-bottom: 3.5rem; }
    @include respond-to("desktop-extra-large") { font-size: 19px; margin-bottom: 3.5rem; }
  }
}
.twob--services__accordion-enter-active {
  animation-duration: .8s;
  animation-fill-mode: both;
  animation-delay: 0.2s;
  animation-name: js-accordion--anime__opend;
}
.twob--services__accordion-leave-active {
  animation-duration: .6s;
  animation-fill-mode: both;
  animation-name: js-accordion--anime__closed;
}
@keyframes js-accordion--anime__opend {
  0% {
    opacity: 0;
    transform: translateY(80px) skewY(4deg) scaleY(1.15);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes js-accordion--anime__closed {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
