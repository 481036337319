import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../translations'

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'es-ES',
  fallbackLocale: 'es',
  localeDir: 'translations',
  enableInSFC: true,
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages
})
