<template>
  <div class="page">
    <div class="section">
      <div class="twob--columns">
        <div class="twob--column is-10">
          <h3>fôr-oh-fôr</h3>
          <h1>
            <span class="invisible--txt">UH OH! </span>{{ $t('errorPage.title') }}
          </h1>
        </div>
      </div>
      <div class="twob--columns align--right">
        <div class="twob--column is-8">
          <div class="twob--txt footer--txt">
            <p>{{ $t('errorPage.txt:1') }}
              <router-link
                to="/"
                class="inner--link"
                @mouseover.native="isOver"
                @mouseleave.native="isOut">
                <span class="link--txt">{{ $t('errorPage.txt:2') }}</span>
                  <div class="marquee-wrapper small--link">
                    <span>{{ $t('marquee.txt') }}</span>
                    <span>{{ $t('marquee.txt') }}</span>
                  </div>
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <app-left-sidebar :midTitle="this.$t('routeText.title:5')"/>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'
import LeftSidebar from '../components/LeftSidebar.vue'

export default {
  name: '404',
  mixins: [cursorMixin],
  components: {
    appLeftSidebar: LeftSidebar
  },
  metaInfo () {
    return {
      title: this.$t('notFound.title'),
      meta: [
        { name: 'description', content: this.$t('notFound.description') }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.error .section {
  height: 100%;
  padding-top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  p {
    margin-bottom: 0;
  }
}
.inner--link { color: #888888; }
</style>
