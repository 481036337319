<template>
  <div
    class="cursor"
    :style="{ borderColor: color }"
    :class="{ active: isActive }">
  </div>
</template>

<script>
import { eventActiveOn, eventActiveOff } from '../main'
import { TweenMax, Power3 } from 'gsap'

export default {
  data () {
    return {
      isActive: false
    }
  },
  props: {
    color: {
      type: String,
      default: '1px solid #b9b9b9'
    }
  },
  methods: {
    startCursor (e) {
      TweenMax.to('.cursor', 1, {
        left: e.clientX,
        top: e.clientY,
        ease: Power3.easeOut
      })
    }
  },
  created () {
    let vm = this
    eventActiveOn.$on('classAdded', () => {
      vm.isActive = true
    })
    eventActiveOff.$on('classRemove', () => {
      vm.isActive = false
    })
    window.addEventListener('mousemove', this.startCursor)
  }
}
</script>

<style lang="scss" scoped>
.cursor {
  border: 2px solid;
  opacity: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: fixed;
  z-index: 1000;
  transition: opacity .6s ease, transform 0.4s ease;
  transform: translate(-50%, -50%) scale(1);
  pointer-events: none;
  display: none;
  @include respond-to("tablet-big") { display: block; }
  &.active {
    transform: scale(1.6) translate(-30%, -30%);
  }
  &.inview {
    opacity: 1;
  }
}
</style>
