<template>
  <div class="projects--images__slider">
    <div class="project--item__image">
      <div class="mask">
        <div class="image--container">
          <img :src="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['image']
}
</script>

<style lang="scss">
.projects--images__slider {
  position: fixed;
  top: 200%;
  left: 50%;
  width: 75vh;
  height: 75vh;
  z-index: -2;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: .16;
  transform: translate3d(-50%,-50%,0);
  transition: top 0ms .25s;
  img {
    max-width: 100%;
    max-height: 100%;
    filter: grayscale(1);
  }
  &.hover {
    z-index: -1;
    top: 50%;
    transition: none;
  }
  &.hover .mask {
    opacity: 1;
    transform: scale(1);
  }
  &.hover .image--container {
    transform: scale(1);
    transition: transform 1.5s cubic-bezier(.23,1,.32,1);
  }
}
.mask {
  overflow: hidden;
  opacity: 0;
  transform: scale(.85);
  transition: opacity .25s ease,transform 1.5s cubic-bezier(.23,1,.32,1);
}
.image--container {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.5);
  transition: transform 0ms .25s;
}
.projects--images__mobile {
  position: absolute;
  display: block;
  @include respond-to("desktop") { display: none; }
}
.swiper-slide-active .projects--images__mobile {
  top: 150% !important;
}
.swiper-slide-active .projects--images__mobile .mask {
  opacity: 1;
  transform: scale(1);
}
.swiper-slide-active .projects--images__mobile .image--container {
  transform: scale(1);
  transition: transform 1.5s cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
