const addClassName = (e) => {
  e.target.parentNode.classList.add(e.target.activeClassName)
}

const removeClassName = (e) => {
  if (e.target.value === '') {
    e.target.parentNode.classList.remove(e.target.activeClassName)
  }
}

export default {
  inserted (el, binding) {
    el.activeClassName = binding.value || 'active'

    el.addEventListener('focus', addClassName, true)
    el.addEventListener('blur', removeClassName, true)
  },
  unbind (el) {
    el.removeEventListener('focus', addClassName, true)
    el.removeEventListener('blur', removeClassName, true)
  },
  componentUpdated (el, binding) {
    if (el.value !== '') {
      el.parentNode.classList.add(el.activeClassName)
    }
  }
}
