<template>
  <div id="app" :style="{ background: bg }">
    <app-loader @pageIn="pageIn"></app-loader>
    <app-orientation-mobile/>
    <app-custom-cursor :class="{ inview: isLoading }" :color="cursorColor"/>
    <canvas id="noise" class="noise"></canvas>
    <app-nav-header/>
    <transition @enter="enter" @leave="leave" mode="out-in" :css="false">
      <router-view></router-view>
    </transition>
    <transition @enter="enter" @leave="leave" mode="out-in" :css="false">
      <app-cookie-advice v-if="isLoading"/>
    </transition>
  </div>
</template>

<script>
import { TweenMax, Power2 } from 'gsap'
import Loader from './components/Loader.vue'
import CustomCursor from './components/CustomCursor.vue'
import NavHeader from './components/Header.vue'
import CookieAdvice from './components/CookieAdvice.vue'
import OrientationMobile from './components/OrientationMobile.vue'
import noise from './plugins/noise.js'

export default {
  components: {
    appLoader: Loader,
    appCustomCursor: CustomCursor,
    appNavHeader: NavHeader,
    appCookieAdvice: CookieAdvice,
    appOrientationMobile: OrientationMobile
  },
  data () {
    return {
      bg: '#ffffff',
      cursorColor: 'rgba(255, 255, 255, 0.6)',
      isLoading: false,
      delay: 4.5,
      onBlur: this.$t('onBlur.title'),
      onFocus: this.$t('onFocus.title')
    }
  },
  watch: {
    '$i18n.locale' () {
      this.onBlur = this.$t('onBlur.title')
      this.onFocus = this.$t('onFocus.title')
    }
  },
  methods: {
    pageIn () {
      this.isLoading = true
      this.delay = 0.6
      document.querySelector('#app').classList.add('is-loaded')
    },
    enter (el, done) {
      TweenMax.fromTo(el, 0.8, {
        y: 220,
        skewY: 3,
        skewX: 0,
        opacity: 0,
        scaleY: 1.15
      }, {
        y: 0,
        skewY: 0,
        skewX: 0,
        scaleY: 1,
        opacity: 1,
        delay: this.delay,
        ease: Power2.easeOut,
        onComplete: done
      })
    },
    leave (el, done) {
      TweenMax.fromTo(el, 0.6, {
        y: 0,
        skewY: 0,
        skewX: 0,
        scaleY: 1,
        opacity: 1
      }, {
        y: 180,
        skewY: 3,
        skewX: 0,
        scaleY: 1.15,
        opacity: 0,
        ease: Power2.easeIn,
        onComplete: done
      })
    }
  },
  created () {
    let _self = this
    window.onblur = function () { document.title = _self.onBlur }
    window.onfocus = function () { document.title = _self.onFocus }
  },
  mounted () {
    noise()
    this.bg = '#141414'
  }
}
</script>

<style lang="scss">
@import '@/styles/setup/normalize.scss';

*,
:after,
:before {
  box-sizing: inherit;
}
html {
  background-color: #141414;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: $font-family-txt;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html.has-scroll-smooth,
.has-scroll-smooth body {
  overflow: auto;
  @include respond-to("desktop") {
    overflow: hidden;
  }
}
.has-scroll-smooth .o-scroll {
  position: relative;
}
.o-scroll {
  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;
}
#app {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
h1 {
  font-size: 15.3vw;
  line-height: .87em;
  color: rgba(255, 255, 255, 0.9);
  font-family: $h1-font-family;
  text-transform: uppercase;
  margin: 0;
  font-weight: 300;
  letter-spacing: 1px;
  user-select: none;
  @include respond-to("tablet-portrait") { font-size: 11vw; }
  @include respond-to("desktop") { font-size: 10vw; }
}
h3 {
  font-family: $h3-font-family;
  font-size: 3.6vw;
  text-transform: none;
  color: #fff;
  margin: 0 0 4.7vw;
  font-weight: normal;
  letter-spacing: 1.5px;
  background: linear-gradient(300deg, #e74c3c 0%, #f1c40f 20%, #1abc9c 40%, #3498db 60%, #9b59b6 80%, #e74c3c 100% );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  user-select: none;
  @include respond-to("tablet-portrait") { font-size: 2.5vw; margin: 0 0 3vw; }
  @include respond-to("desktop") { font-size: 1.6vw; margin: 0 0 2vw; }
}
h4 {
  font-family: $h4-font-family;
  font-size: 7vw;
  text-transform: uppercase;
  line-height: .87em;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  margin-bottom: 4vw;
  font-weight: 700;
  letter-spacing: 1px;
  user-select: none;
  @include respond-to("tablet-portrait") { font-size: 3.3vw; margin-bottom: 2vw; }
  @include respond-to("desktop-medium") { font-size: 3vw; }
  @include respond-to("desktop-extra-large") { font-size: 2.8vw; }
}
h5 {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
  font-size: 4vw;
  line-height: 1.8;
  letter-spacing: 0rem;
  margin: 0;
  margin-top: 4vw;
  user-select: none;
  @include respond-to("tablet-portrait") { font-size: 2.4vw;  margin-top: 3vw; }
  @include respond-to("desktop") { font-size: 1.9vw; letter-spacing: -.02rem;  margin-top: 2vw; }
  @include respond-to("desktop-extra-large") { letter-spacing: -.04rem; }
}
a {
  font-family: $font-family-txt;
  color: #fff;
  text-decoration: none;
  user-select: none;
  &:focus { outline: 0; }
}
p {
  color: #888888;
  margin: 0;
  letter-spacing: 0;
  font-size: 3.4vw;
  line-height: 2.1;
  margin-bottom: 4.4vw;
  user-select: none;
  @include respond-to("tablet-portrait") { font-size: 1.55vw; letter-spacing: -.01rem; margin-bottom: 3vw; }
  @include respond-to("desktop") { letter-spacing: -.02rem; margin-bottom: 2.4vw; }
  @include respond-to("desktop-medium") { font-size: 1.35vw; }
}
p.no--margin__bottom {
  margin-bottom: 0;
}
.noise {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: .2;
  z-index: 0;
  pointer-events: none;
  @include respond-to("desktop") { opacity: .4; }
}
@keyframes colorchange {
  0% { background-color: #e74c3c; }
  20% { background-color: #f1c40f; }
  40% { background-color: #1abc9c; }
  60% { background-color: #3498db; }
  80% { background-color: #9b59b6; }
  100% { background-color: #e74c3c; }
}
// Columns
.main {
  position: relative;
  width: 100vw;
  height: 100%;
  @include respond-to("desktop") {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.section {
  position: relative;
  padding: 34vw 4vw 0;
  @include respond-to("tablet-portrait") { padding: 24vw 17vw 0; }
  @include respond-to("desktop") { padding: 12vw 17vw 0; }
  @include respond-to("desktop-medium") { padding: 11vw 17vw 0; }
  @include respond-to("desktop-extra-large") { padding: 9vw 17vw 0; }
}
.center--section {
  position: relative;
  padding: 24vw 4vw 0;
  @include respond-to("tablet-portrait") { padding: 18vw 17vw 0; }
  @include respond-to("desktop") { padding: 12vw 17vw 0; }
}
.middle--section {
  position: relative;
  padding: 0 4vw 0;
  @include respond-to("tablet-portrait") { padding: 0 17vw 0; }
}
.center--section__fullwidth {
  position: relative;
  padding: 24vw 0 0;
  @include respond-to("tablet-portrait") { padding: 18vw 0 0; }
  @include respond-to("desktop") { padding: 12vw 0 0; }
}
.footer--section {
  position: relative;
  padding: 24vw 4vw 9vw;
  @include respond-to("tablet-portrait") { padding: 16vw 17vw 14vw; }
  @include respond-to("desktop") { padding: 12vw 17vw 9vw; }
}
.section--credits {
  position: relative;
  padding: 14vw 4vw 0;
  @include respond-to("tablet-portrait") { padding: 2vw 17vw 0; }
}
.twob--columns {
  display: flex;
  flex-wrap: wrap;
}
.twob--column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}
.twob--column.is-12 {
  flex: none;
  width: 100%;
}
.twob--column.is-10 {
  flex: none;
  width: 100%;
  @include respond-to("desktop") { width: 83.33333%; }
}
.twob--column.is-9 {
  flex: none;
  width: 100%;
  @include respond-to("tablet-portrait") { width: 75%; }
}
.twob--column.is-8 {
  flex: none;
  width: 100%;
  @include respond-to("tablet-portrait") { width: 66.66667%; }
}
.twob--column.is-6 {
  flex: none;
  width: 100%;
  @include respond-to("desktop") { width: 50%; }
  &.is--footer { width: 50%; }
}
.twob--column.is-4 {
  flex: none;
  width: 33.33333%;
}
.twob--column.is-offset-2 {
  margin-left: 0;
  @include respond-to("tablet-portrait") { margin-left: 16.66667%; }
}
.align--right {
  justify-content: flex-end;
}
.scroll--line {
  position: fixed;
  height: 50px;
  right: 3.8vw;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  @include respond-to("tablet-portrait") { right: 4.2vw; }
  @include respond-to("desktop") { display: block; }
}
#scrollOut:before,
#scrollOut:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 0;
}
#scrollOut {
  transition: opacity .4s ease;
}
#scrollOut:before {
  background: #808080;
}
#scrollOut:after {
  z-index: 2;
  background: #fff;
  transform: scaleY(0);
  animation: scrollDownLine 2s cubic-bezier(.4,0,0,1) normal infinite .75s;
}
#scrollOut.hide {
  opacity: 0;
}
@keyframes scrollDownLine {
  0% {
    transform: scaleY(0);
    transform-origin: top center
  }
  50% {
    transform: scaleY(1);
    transform-origin: top center
  }
  51% {
    transform: scaleY(1);
    transform-origin: bottom center
  }
  to {
    transform: scaleY(0);
    transform-origin: bottom center
  }
}
@keyframes color {
  0% { color: #e74c3c; }
  20% { color: #f1c40f; }
  40% { color: #1abc9c; }
  60% { color: #3498db; }
  80% { color: #9b59b6; }
  100% { color: #e74c3c; }
}
.color--action:hover {
  animation: color .4s infinite;
}
.scroll--animation {
  display: block;
  opacity: 0;
  perspective: 600px;
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateY(100px) skewY(4deg) scaleY(1.15);
  transition: opacity 1.2s cubic-bezier(.215,.61,.355,1),transform 1.2s cubic-bezier(.215,.61,.355,1);
  will-change: opacity, transform;
}
.scroll--animation.is-inview {
  transform: none;
  opacity: 1;
  transition-delay: .2s;
}
.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
}
</style>
