import Home from '../views/Home'
import Projects from '../views/Projects'
import Contact from '../views/Contact'
import Privacy from '../views/Privacy'
import Cookies from '../views/Cookies'
import NotFound from '../views/404'

export default [
  {
    path: '/',
    name: 'Twob',
    component: () => import(/* webpackChunkName: "" */ '../views/Home.vue')
  },
  {
    path: '/proyectos',
    name: 'Proyectos',
    component: () => import(/* webpackChunkName: "" */ '../views/Projects.vue')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import(/* webpackChunkName: "" */ '../views/Contact.vue')
  },
  {
    path: '/politica-de-privacidad',
    name: 'Política de privacidad',
    component: () => import(/* webpackChunkName: "" */ '../views/Privacy.vue')
  },
  {
    path: '/politica-de-cookies',
    name: 'Política de cookies',
    component: () => import(/* webpackChunkName: "" */ '../views/Cookies.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "" */ '../views/404.vue')
  }
]
