<template>
  <div class="main">
    <div class="o-scroll" ref="scrollSections" @scroll="scrolled(this)">
      <div class="section legal--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-10">
            <h3>{{ $t('privacyPage.subtitle') }}</h3>
            <h1>
              <span class="invisible--txt">{{ $t('privacyPage.title:1') }}</span>{{ $t('privacyPage.title:2') }}
            </h1>
          </div>
        </div>
      </div>
      <div class="middle--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-10">
            <div class="footer--txt">
              <p class="title--service">{{ $t('privacyPage.inner:title:1') }}</p>
              <p>{{ $t('privacyPage.inner:txt:1') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="middle--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-10">
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:2') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:2') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:3') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:3') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:4') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:5') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:6') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:7') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:4') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:8') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:5') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:9') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:10') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:11') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:12') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:13') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:14') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:6') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:15') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:7') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:16') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:17') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:18') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:19') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:8') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:20') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:21') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:22') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:23') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:9') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:24') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:25') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:26') }}</p>
            <p class="title--service scroll--animation" data-scroll>{{ $t('privacyPage.inner:title:10') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:27') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:28') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:29') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:30') }}</p>
            <p class="title--service scroll--animation" data-scroll>1{{ $t('privacyPage.inner:title:11') }}</p>
            <p class="scroll--animation" data-scroll>{{ $t('privacyPage.inner:txt:31') }}</p>
          </div>
        </div>
      </div>
      <app-contact-footer/>
      <app-footer/>
    </div>
    <app-left-sidebar :midTitle="this.$t('routeText.title:4')"/>
    <div class="scroll--line">
      <div id="scrollOut"></div>
    </div>
  </div>
</template>

<script>
import { scrollMixin } from '../mixins/scrollMixin.js'
import { updateMixin } from '../mixins/updateMixin.js'
import { scrollerMixin } from '../mixins/scrollerMixin.js'
import LeftSidebar from '../components/LeftSidebar.vue'
import ContactFooter from '../components/ContactFooter.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Política de privacidad',
  components: {
    appLeftSidebar: LeftSidebar,
    appFooter: Footer,
    appContactFooter: ContactFooter
  },
  mixins: [
    scrollMixin,
    updateMixin,
    scrollerMixin
  ],
  mounted () {
    this.updateScroll(1000)
  }
}
</script>

<style lang="scss">
.legal--section {
  margin-bottom: 2vw;
}
</style>
