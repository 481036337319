<template>
  <span class="slider--container">
    <swiper :options="swiperOption" :cleanStylesOnDestroy="false" ref="mySwiper">
      <swiper-slide v-for="(item, index) in items" :key="index">
        <div
          class="swiper--inner"
          @mouseover="selectItem(index)"
          @mouseleave="deselectItem(index)"
          :class="{hover: index === activeItem}">
          <a
            :href="item.url"
            class="project--link inner--link"
            target="_blank"
            @mouseover="isOver"
            @mouseleave="isOut">
            <h2>
              <span class="visible--link">{{ item.title }}</span>
              <div class="marquee-wrapper">
                <span>{{ item.info }}</span>
                <span>{{ item.info }}</span>
              </div>
            </h2>
            <h6>{{ item.number }}</h6>
          </a>
          <app-svg-icon></app-svg-icon>
        </div>
      </swiper-slide>
    </swiper>
    <div class="projects--images__container">
      <app-projects-images v-for="(item, index) in items" :key="index" :image="item.image" :class="{hover: index === activeItem}"></app-projects-images>
    </div>
  </span>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'
import SvgIcon from './SvgIcon.vue'
import ProjectsImages from './ProjectsImages.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide,
    appSvgIcon: SvgIcon,
    appProjectsImages: ProjectsImages
  },
  mixins: [
    cursorMixin
  ],
  data () {
    return {
      activeItem: null,
      swiperOption: {
        direction: 'vertical',
        loop: true,
        longSwipes: false,
        shortSwipes: false,
        notNextTick: true,
        freeMode: true,
        freeModeSticky: true,
        simulateTouch: false,
        slidesPerView: 4,
        spaceBetween: 20,
        loopAdditionalSlides: 9,
        watchSlidesVisibility: true,
        centeredSlides: true,
        mousewheel: {
          sensitivity: 0.3,
          eventsTarged: '.projects'
        },
        breakpoints: {
          1820: {
            spaceBetween: 0
          },
          1600: {
            slidesPerView: 5,
            spaceBetween: 120
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 60
          },
          970: {
            freeMode: false,
            slidesPerView: 8,
            spaceBetween: 0,
            speed: 1000,
            longSwipes: false
          }
        }
      }
    }
  },
  computed: {
    items () {
      return [
        { key: 1, title: 'Dos Santas', number: '01', url: 'https://www.dossantas.com/', info: this.$t('projects.info.1'), image: require(`@/assets/img/dos-santas-main-bg.jpg`) },
        { key: 2, title: 'Monika Frias', number: '02', url: 'http://monikafrias.com/', info: this.$t('projects.info.2'), image: require('@/assets/img/monika-main-bg.jpg') },
        { key: 3, title: 'La Textil', number: '03', url: 'https://latextil.twobtest.com/', info: this.$t('projects.info.3'), image: require(`@/assets/img/la-textil-main-bg.jpg`) },
        { key: 4, title: 'Oottum C.', number: '04', url: 'https://oottum.com/', info: this.$t('projects.info.4'), image: require(`@/assets/img/oottum-collective-main-bg.jpg`) },
        { key: 5, title: 'Jaiak T&D', number: '05', url: 'https://jaiak.es', info: this.$t('projects.info.5'), image: require('@/assets/img/jaiak-main-bg.jpg') },
        { key: 6, title: 'Immaclé', number: '06', url: 'http://immacle.com/', info: this.$t('projects.info.6'), image: require('@/assets/img/immacle-main-bg.jpg') },
        { key: 7, title: 'Miss Little', number: '07', url: 'http://misslittlethings.com', info: this.$t('projects.info.7'), image: require('@/assets/img/mis-main-bg.jpg') },
        { key: 8, title: 'Pixel Video', number: '08', url: 'https://ospixel.com', info: this.$t('projects.info.8'), image: require('@/assets/img/pixel-main-bg.jpg') },
        { key: 9, title: 'Heu & Neu', number: '09', url: 'http://heu-neu.com/', info: this.$t('projects.info.9'), image: require('@/assets/img/heuneu-main-bg.jpg') }
      ]
    },
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    selectItem (index) {
      this.activeItem = index
    },
    deselectItem (index) {
      this.activeItem = !index
    }
  },
  beforeDestroy () {
    let swiperIndex = this.$refs.mySwiper.swiper.activeIndex
    this.swiper.destroy(false, false)
    this.swiper.init()
    this.swiper.slideTo(swiperIndex, 0, false)
  }
}
</script>

<style lang="scss">
.slider--container {
  position: fixed;
  height: 100vh;
  padding: 0 4vw;
  margin: 0 auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @include respond-to("tablet-portrait") { padding: 0 17vw; }
}
.swiper-container {
  height: 100%;
  overflow: visible;
  position: relative;
  z-index: 0;
}
.swiper--inner {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  &.hover svg {
    opacity: 1;
    transform: translate3d(0vw, 0vw, 0px) scale3d(1, 1, 1);
  }
  &.hover h6 {
    opacity: 0;
    transform: translate3d(-0.5vw, 0.5vw, 0px) scale3d(1, 1, 1);
  }
}
h2 {
  font-family: $font-family-main;
  font-size: 16.2vw;
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
  opacity: .06;
  line-height: 1.24;
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
  transform-origin: 0 0;
  white-space: nowrap;
  transform-style: preserve-3d;
  transition: opacity 0.4s cubic-bezier(0.49, 0.03, 0.14, 0.97);
  @include respond-to("tablet-portrait") { font-size: 12.4vw; }
  @include respond-to("desktop") { font-size: 12.8vw; }
  @include respond-to("desktop-extra-large") { font-size: 12.2vw; }
}
h6 {
  font-family: $font-family-main-bold;
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);
  opacity: .06;
  margin-bottom: 0;
  margin-top: 3.1vw;
  margin-right: auto;
  margin-left: .2vw;
  writing-mode: vertical-rl;
  will-change: transform, opacity;
  transition: all 0.4s cubic-bezier(0.49, 0.03, 0.14, 0.97);
  @include respond-to("tablet-portrait") { font-size: 1.1vw; margin-left: .25vw; }
  @include respond-to("desktop") { margin-top: 2.6vw; font-size: .7vw; }
  @include respond-to("desktop-extra-large") { margin-top: 2.5vw; font-size: .63vw; }
}
.swiper-slide {
  pointer-events: none;
}
.swiper-slide-active h2,
.swiper-slide-active h6 { opacity: 1; }
.project--link {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  text-decoration: none;
  pointer-events: none;
  max-width: 100%;
}
.swiper-slide-active a { pointer-events: all; }
.swiper-slide-active .project--link__icon {
  opacity: 1;
  bottom: -3.6vw;
  margin-left: 0;
  @include respond-to("tablet-portrait") {
    bottom: -2.4vw;
    margin-left: 1vw;
  }
  @include respond-to("desktop") {
    opacity: 0;
    bottom: -2.5vw;
    margin-left: 2vw;
  }
}
</style>
