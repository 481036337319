<template>
  <form
    name="contact-form"
    method="POST"
    netlify-honeypot="bot-field"
    data-netlify="true"
    id="contact-form"
    @submit.prevent="handleSubmit">
    <p class="hidden--bot">
      <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
    </p>
    <div class="twob--columns">
      <div class="twob--column is-6 is--field__left">
        <div
          class="form-field"
          :class="{invalid: submitted && $v.form.yourName.$error}"
          @mouseover="isOver"
          @mouseleave="isOut">
         <span class="form-line"/>
          <input
            id="name"
            v-model="form.yourName"
            @blur="$v.form.yourName.$touch()"
            v-magic-focus
            name="yourName"
            class="form-input"
            type="text"/>
          <label
            for="name"
            class="form-label">{{ $t('contactForm.your:name') }}</label>
          <span class="error--advice" v-if="submitted && !$v.form.yourName.required">{{ $t('contactForm.name:required') }}</span>
        </div>
      </div>
      <div class="twob--column is-6 is--field__right">
        <div
          class="form-field"
          @mouseover="isOver"
          @mouseleave="isOut">
         <span class="form-line"/>
          <input
            id="brand"
            v-model="form.yourBrand"
            v-magic-focus
            name="yourBrand"
            class="form-input"
            type="text"/>
          <label
            for="brand"
            class="form-label">{{ $t('contactForm.your:brand') }}</label>
        </div>
      </div>
    </div>
    <div class="twob--columns">
      <div class="twob--column is-6 is--field__left">
        <div
          class="form-field"
          :class="{invalid: submitted && $v.form.yourEmail.$error || $v.form.yourEmail.$error }"
          @mouseover="isOver"
          @mouseleave="isOut">
         <span class="form-line"/>
          <input
            id="email"
            v-model="form.yourEmail"
            @blur="$v.form.yourEmail.$touch()"
            v-magic-focus
            name="yourEmail"
            class="form-input"
            type="email"/>
          <label
            for="email"
            class="form-label">{{ $t('contactForm.your:email') }}</label>
            <span class="error--advice" v-if="submitted && !$v.form.yourEmail.required">{{ $t('contactForm.email:required') }}</span>
            <span class="error--advice" v-if="!$v.form.yourEmail.email">{{ $t('contactForm.email:valid') }}</span>
        </div>
      </div>
      <div class="twob--column is-6 is--field__right">
        <div
          class="form-field"
          @mouseover="isOver"
          @mouseleave="isOut">
         <span class="form-line"/>
          <input
            id="phone"
            v-model="form.yourPhone"
            v-magic-focus
            name="yourPhone"
            class="form-input"
            type="tel"/>
          <label
            for="phone"
            class="form-label">{{ $t('contactForm.your:phone') }}</label>
        </div>
      </div>
    </div>
    <div class="twob--columns">
      <div class="twob--column is-6 is--field__left">
        <div
          class="form-field"
          @mouseover="isOver"
          @mouseleave="isOut">
         <span class="form-line"/>
          <input
            id="website"
            v-model="form.yourWebsite"
            v-magic-focus
            name="yourWebsite"
            class="form-input"
            type="text"/>
          <label
            for="website"
            class="form-label">Website</label>
        </div>
      </div>
      <div class="twob--column is-6 is--field__right">
        <div
          class="form-field"
          @mouseover="isOver"
          @mouseleave="isOut">
          <multiselect
            :searchable="true"
            class="form-input"
            v-model="form.selectedWhere"
            name="selectedWhere"
            :options="$t('contactForm.select:optionsWhere.options')"
            :placeholder="$t('contactForm.select:how')">
          </multiselect>
        </div>
      </div>
    </div>
    <div class="twob--columns">
      <div class="twob--column is-12">
        <p class="title--service">{{ $t('contactForm.title:services') }}</p>
        <div class="chekbox--row">
          <div
            class="form-field ckeckbox--field"
            @mouseover="isOver"
            @mouseleave="isOut">
            <div class="checkbox--input__border"></div>
            <input
              id="webDesign"
              v-model="form.yourwebDesign"
              name="yourwebDesign"
              class="checkbox--input"
              type="checkbox"/>
            <label for="webDesign" class="checkbox--input__label">{{ $t('contactForm.service:dd') }}</label>
          </div>
          <div
            class="form-field ckeckbox--field"
            @mouseover="isOver"
            @mouseleave="isOut">
            <div class="checkbox--input__border"></div>
            <input
              id="eCommerce"
              v-model="form.youreCommerce"
              name="youreCommerce"
              class="checkbox--input"
              type="checkbox"/>
            <label for="eCommerce" class="checkbox--input__label">E-commerce</label>
          </div>
        </div>
        <div class="chekbox--row">
          <div
            class="form-field ckeckbox--field"
            @mouseover="isOver"
            @mouseleave="isOut">
            <div class="checkbox--input__border"></div>
            <input
              id="hosting"
              v-model="form.yourHosting"
              name="yourHosting"
              class="checkbox--input"
              type="checkbox"/>
            <label for="hosting" class="checkbox--input__label">Hosting & SSL</label>
          </div>
          <div
            class="form-field ckeckbox--field"
            @mouseover="isOver"
            @mouseleave="isOut">
            <div class="checkbox--input__border"></div>
            <input
              id="seo"
              v-model="form.yourSeo"
              name="yourSeo"
              class="checkbox--input"
              type="checkbox"/>
            <label for="seo" class="checkbox--input__label">SEO & Social</label>
          </div>
        </div>
      </div>
    </div>
    <div class="twob--columns">
      <div class="twob--column is-6 is--field__left">
        <div
          class="form-field"
          @mouseover="isOver"
          @mouseleave="isOut">
          <multiselect
            :searchable="true"
            class="form-input"
            v-model="form.selectedTimeline"
            name="selectedTimeline"
            :options="$t('contactForm.select:optionsTimneline.options')"
            :placeholder="$t('contactForm.select:time')">
          </multiselect>
        </div>
      </div>
      <div class="twob--column is-6 is--field__right">
        <div
          class="form-field"
          @mouseover="isOver"
          @mouseleave="isOut">
          <multiselect
            :searchable="true"
            class="form-input"
            v-model="form.selectedBudget"
            name="selectedBudget"
            :options="$t('contactForm.select:optionsBudget.options')"
            :placeholder="$t('contactForm.select:budget')">
          </multiselect>
        </div>
      </div>
    </div>
    <div class="twob--columns">
      <div class="twob--column is-12">
        <div class="form-field textarea-field">
          <span class="form-line"/>
          <textarea
            id="message"
            v-model="form.yourMessage"
            v-magic-focus
            name="yourMessage"
            rows="5"
          />
          <label
            for="message"
            class="form-label textarea-label"
          >
            {{ $t('contactForm.your:message') }}
          </label>
        </div>
      </div>
    </div>
    <div class="twob--column">
      <div class="twob--column is-12">
        <transition
          @enter="formEnter"
          :css="false">
          <div class="form--error" v-if="isError">
            <h4 class="title--footer__contact">{{ $t('contactError.message:1') }}</h4>
            <p>{{ $t('contactError.message:2') }}</p>
          </div>
        </transition>
        <div class="form-field">
          <div class="btn--wrap">
            <button
              type="submit"
              class="btn"
              :class="{ loading: buttonDisabled }"
              :disabled="buttonDisabled"
              @submit="$v.$touch()"
              @mouseover="isOver"
              @mouseleave="isOut">
              {{ $t('contactForm.submit:button') }}
              <div class="btn--loader"/>
            </button>
          </div>
        </div>
        <div class="form--agree">
          <p class="form-agree__paragraph">{{ $t('contactForm.privacy:1') }} <router-link to="/politica-de-privacidad" class="dot text--link__red" @mouseover.native="isOver" @mouseleave.native="isOut">{{ $t('contactForm.privacy:2') }}</router-link></p>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'
import { TweenMax, Power2 } from 'gsap'
import Multiselect from 'vue-multiselect'
import magicFocus from '@/directives/focus'
import { required, email } from 'vuelidate/lib/validators'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default {
  components: {
    Multiselect
  },
  mixins: [
    cursorMixin
  ],
  directives: {
    magicFocus
  },
  data () {
    return {
      buttonDisabled: false,
      submitted: false,
      isError: false,
      form: {
        yourName: '',
        yourBrand: '',
        yourEmail: '',
        yourPhone: '',
        yourWebsite: '',
        selectedWhere: null,
        selectedTimeline: null,
        selectedBudget: null,
        yourwebDesign: '',
        youreCommerce: '',
        yourHosting: '',
        yourSeo: '',
        yourMessage: ''
      }
    }
  },
  validations: {
    form: {
      yourName: {
        required
      },
      yourEmail: {
        required,
        email
      }
    }
  },
  methods: {
    handleSubmit (e) {
      const form = e.target

      this.isError = false
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.submitted = true
        this.$emit('scrollFixError')
        return
      }

      this.submitted = true
      this.buttonDisabled = true

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.form
        })
      })
      .then(() => {
        this.$emit('onSuccess')
      })
      .catch(() => {
        this.isError = true
        this.buttonDisabled = false
        this.$emit('scrollChange')
      })
    },
    formEnter (el, done) {
      TweenMax.fromTo(el, 0.8, {
        y: 180,
        skewY: 4,
        skewX: 0,
        opacity: 0,
        scaleY: 1.2
      }, {
        y: 0,
        skewY: 0,
        skewX: 0,
        scaleY: 1,
        opacity: 1,
        delay: 0,
        ease: Power2.easeOut,
        onComplete: done
      })
    }
  }
}
</script>

<style lang="scss">
.form-field {
  position: relative;
  margin: 36px 0 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #141414;
  &.invalid .form-line:after,
  &.invalid .form-line:before { background: #e74c3c; }
  &.invalid.active .form-label { color: #e74c3c; }
  @include respond-to("tablet-portrait") { margin: 30px 0 0; }
  @include respond-to("desktop-medium") { margin: 36px 0 0; }
  @include respond-to("desktop-large") { margin: 44px 0 0; }
  @include respond-to("desktop-extra-large") { margin: 54px 0 0; }
}
.invalid--wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.error--advice {
  position: absolute;
  color: rgba(255, 255, 255, 0.9);
  right: 0;
  bottom: -46px;
  font-size: 10px;
  display: block;
  @include respond-to("desktop-medium") { font-size: 12px; }
  @include respond-to("desktop-extra-large") { font-size: 13px; bottom: -52px; }
}
.form-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  overflow: hidden;
}
.form-line:after,
.form-line:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
}
.form-line:before {
  background: #888888;
}
.form-line:after {
  background: rgba(255, 255, 255, 0.9);
  transform: translateX(-100%);
  transition: all .7s cubic-bezier(.19,1,.22,1);
}
.form-input {
  font-size: 20px;
  padding-top: 16px;
  color: rgba(255, 255, 255, 0.9);
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  line-height: 60px;
  border: 0;
  letter-spacing: .5px;
  &:focus { outline: 0; }
  @include respond-to("tablet-portrait") { font-size: 18px; padding-top: 10px; }
  @include respond-to("desktop-medium") { font-size: 20px; }
  @include respond-to("desktop-extra-large") { font-size: 24px; padding-top: 0; }
}
.form-label {
  font-family: $font-family-txt;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 4.5;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9;
  transition: all .7s cubic-bezier(.19,1,.22,1);
  @include respond-to("desktop-large") { font-size: 18px; line-height: 3.5; }
  @include respond-to("desktop-extra-large") { font-size: 22px; line-height: 2.5; }
}
.form-field.active .form-line:after {
  transform: translateX(0);
}
.form-field.active .form-label {
  font-size: 12px;
  color: #888888;
  transform: translateY(-24px);
  pointer-events: none;
  @include respond-to("desktop-medium") { font-size: 13px; }
  @include respond-to("desktop-extra-large") { font-size: 14px; transform: translateY(-30px); }
}
.is--field__left {
  @include respond-to("desktop") { padding-right: 2%; }
}
.is--field__right {
  @include respond-to("desktop") { padding-left: 2%; }
}
#contact-form {
  .multiselect {
    border-bottom: 1px solid #888888;
    padding-top: 0;
  }
  .multiselect__tags {
    background: transparent;
    border: 0;
    height: 60px;
    line-height: 60px;
    padding-top: 0;
    padding-left: 0;
  }
  .multiselect__placeholder {
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    line-height: 4.5;
    height: 60px;
    margin: 0;
    padding: 0;
    @include respond-to("desktop-large") { font-size: 18px; line-height: 3.5; }
    @include respond-to("desktop-extra-large") { font-size: 22px; line-height: 2.5; }
  }
  .multiselect__select {
    width: 1rem;
    height: .5625rem;
    padding: 0;
    right: 2px;
    top: 38%;
    @include respond-to("tablet-portrait") { top: 40%; }
    @include respond-to("desktop-medium") { top: 42%; }
    @include respond-to("desktop-large") { top: 35%; }
    @include respond-to("desktop-extra-large") { top: 32%; }
  }
  .multiselect__select:before {
    position: absolute;
    margin-top: 0;
    width: 1rem;
    height: .5625rem;
    background: url('../assets/img/dropdown-arrow.svg') no-repeat center center;
    border-color: transparent;
    border-width: 0;
    transition: all 1s ease;
  }
  .multiselect--active .multiselect__select {
    transform: none;
  }
  .multiselect--active .multiselect__select:before {
    transform: rotateX(-180deg);
  }
  .multiselect__single,
  .multiselect__input {
    padding-left: 0;
    margin-bottom: 0;
    background: transparent;
    color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include respond-to("tablet-portrait") { font-size: 18px; }
    @include respond-to("desktop-medium") { font-size: 20px; }
    @include respond-to("desktop-extra-large") { font-size: 24px; }
  }
  .multiselect__content-wrapper {
    background: #141414;
    border: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .multiselect--active .multiselect__content-wrapper {
    animation: fadeInUp 600ms;
  }
  .multiselect__option {
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    @include respond-to("desktop") { font-size: 15px; }
    @include respond-to("desktop-medium") { font-size: 16px; }
    @include respond-to("desktop-large") { font-size: 17px; }
    @include respond-to("desktop-extra-large") { font-size: 18px; }
  }
  .multiselect__option--highlight {
    background: #fff;
    color: #141414;
  }
  .multiselect__option--highlight:after { display: none; }
  .multiselect__option--selected {
    background: #141414;
    color: #fff;
    font-weight: 300;
  }
  .multiselect--active .multiselect__placeholder {
    display: block;
  }
  ::placeholder {
    color: #888888;
    font-size: 16px;
    @include respond-to("desktop-large") { font-size: 18px; }
    @include respond-to("desktop-extra-large") { font-size: 22px; }
  }
  :-ms-input-placeholder {
    color: #888888;
    font-size: 16px;
    @include respond-to("desktop-large") { font-size: 18px; }
    @include respond-to("desktop-extra-large") { font-size: 22px; }
  }
  ::-webkit-input-placeholder {
    color: #888888;
    font-size: 16px;
    @include respond-to("desktop-large") { font-size: 18px; }
    @include respond-to("desktop-extra-large") { font-size: 22px; }
  }
}
.title--service {
  font-family: $font-family-main;
  margin-top: 66px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .05em;
  font-size: 22px;
  line-height: 1;
  margin-bottom: 32px;
  color: rgba(255, 255, 255, 0.9);
  @include respond-to("desktop-medium") { font-size: 24px; }
  @include respond-to("desktop-large") { font-size: 26px; }
  @include respond-to("desktop-extra-large") { font-size: 33px; }
}
.chekbox--row {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 14px;
  @include respond-to("desktop-medium") {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.ckeckbox--field {
  margin-top: 0;
  height: 68px;
  line-height: 68px;
  width: 100%;
  margin-bottom: 14px;
  @include respond-to("desktop-medium") { width: 48%; margin-bottom: 0; }
}
.checkbox--input__border {
  border: 1px solid #888888;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: border-color .3s;
}
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
.checkbox--input {
  position: absolute;
  visibility: hidden;
}
.checkbox--input__label {
  position: relative;
  display: block;
  font-weight: 300;
  font-size: 16px;
  padding: 0 0 0 60px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  transition: color .3s ease;
  @include respond-to("desktop-medium") { font-size: 14px; }
  @include respond-to("desktop-large") { font-size: 16px; }
  @include respond-to("desktop-extra-large") { font-size: 22px; }
}
.ckeckbox--field .checkbox--input:checked+.checkbox--input__label:after,
.ckeckbox--field .checkbox--input:checked+.checkbox--input__label:before,
.ckeckbox--field .checkbox--input:not(:checked)+.checkbox--input__label:after,
.ckeckbox--field .checkbox--input:not(:checked)+.checkbox--input__label:before {
  content: "";
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translate3d(0,-50%,0);
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.ckeckbox--field .checkbox--input:checked+.checkbox--input__label:before,
.ckeckbox--field .checkbox--input:not(:checked)+.checkbox--input__label:before {
  border: 2px solid #888888;
}
.ckeckbox--field .checkbox--input:checked+.checkbox--input__label:after,
.ckeckbox--field .checkbox--input:not(:checked)+.checkbox--input__label:after {
  background-color: #1abc9c;
  transition: all .2s;
}
.ckeckbox--field .checkbox--input:not(:checked)+.checkbox--input__label:after {
  opacity: 0;
  transform: translate3d(0,-50%,0) scale(0);
}
.ckeckbox--field .checkbox--input:checked+.checkbox--input__label:after {
  opacity: 1;
  transform: translate3d(0,-50%,0) scale(1);
}
textarea {
  position: relative;
  font-size: 20px;
  line-height: 1.6;
  width: 100%;
  background: transparent;
  border: none;
  z-index: 9;
  resize: none;
  color: rgba(255, 255, 255, 0.9);
  padding: 24px 0;
  &:focus { outline: 0; }
  @include respond-to("tablet-portrait") { font-size: 18px; }
  @include respond-to("desktop-medium") { font-size: 20px; }
  @include respond-to("desktop-extra-large") { font-size: 24px; }
}
.textarea-field {
  height: auto;
  line-height: 0;
}
.btn--wrap {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.btn {
  font-family: $font-family-main;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .04em;
  font-size: 22px;
  text-align: center;
  padding: 0;
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 0;
  width: 100%;
  color: #141414;
  display: block;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  transition: all .25s ease;
  &:hover {
    background: #888888;
    border-color: #888888;
    background-position-x: 90%;
    color: rgba(255, 255, 255, 0.9);
  }
  &.loading { pointer-events: none; }
  @include respond-to("desktop-medium") { font-size: 24px; }
  @include respond-to("desktop-large") { font-size: 26px; }
  @include respond-to("desktop-extra-large") { font-size: 30px; }
}
.form--agree {
  margin: 18px 0 0;
  padding: 0;
  @include respond-to("desktop") {
    margin: 22px 0 0;
    padding: 0 14%;
  }
  @include respond-to("desktop-extra-large") {
    margin: 30px 0 0;
    padding: 0 20%;
  }
}
.form-agree__paragraph,
.text--link__red {
  line-height: 1.225rem;
  font-size: 10px;
  text-align: center;
  @include respond-to("tablet-portrait") { font-size: 11px; line-height: 1.525rem; }
  @include respond-to("desktop-medium") { font-size: 13px; }
  @include respond-to("desktop-extra-large") { font-size: 14px; }
}
.text--link__red:hover {
  animation: color .4s infinite;
}
.btn--loader {
  position: absolute;
  top: 50%;
  left: auto;
  right: 1.5em;
  margin: -.5em;
  opacity: 0;
  z-index: -100;
  width: 1em;
  height: 1em;
  color: #141414;
  display: inline-block;
  transition: all .3s;
  transition-timing-function: ease-in;
  animation: spin 1s infinite;
  &:after {
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    display: inline-block;
    border-radius: 50%;
    border-style: solid;
    box-sizing: border-box;
    border-width: .15em;
    border-color: currentColor currentColor currentColor transparent;
    background: center center no-repeat;
    background-size: cover;
  }
}
.btn.loading .btn--loader {
  opacity: 1;
  z-index: auto;
  visibility: visible;
}
.form--error {
  position: relative;
  margin-top: 66px;
  text-align: center;
}
.basic--options {
  position: relative;
  z-index: 9;
}
.hidden--bot {
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin: 0;
}
@keyframes spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(.55,.055,.675,.19)
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }
  to {
    transform: rotate(1800deg)
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(80px) skewY(6deg) scaleY(1.2);
  }
  to {
    opacity: 1;
    transform: none
  }
}
</style>
