export const scrollerMixin = {
  mounted () {
    const sceneScroll = this.$scrollmagic.scene({
      triggerElement: '#trigger',
      triggerHook: 1,
      duration: 0.01
    })
      .setTween('#scrollOut', 0.01, {
        className: '+=hide'
      })
    this.$scrollmagic.addScene(sceneScroll)
  }
}
