<template>
  <div class="twob--intro__wrapper" v-if="loaderOut">
    <div class="twob--intro">
      <div class="twob--intro__inner">
        <div class="twob--intro__logo">
          <i>t</i>
          <i>w</i>
          <i>o</i>
          <i>b</i>
          <i>r</i>
          <i>o</i>
          <i>t</i>
          <i>h</i>
          <i>e</i>
          <i>r</i>
          <i>s</i>
        </div>
      </div>
      <div class="twob--intro__divider"></div>
      <div class="twob--intro__inner">
        <h3 class="twob--tagline">{{ $t('loader.title') }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { TimelineMax, Power4 } from 'gsap'

export default {
  data () {
    return {
      loaderOut: true,
      tl: new TimelineMax(),
      time: 1,
      ease: Power4.easeOut
    }
  },
  methods: {
    initLoader () {
      this.tl.to('.twob--intro__wrapper', 0.01, { display: 'flex', delay: 1 })
      this.tl.fromTo('.twob--intro .twob--intro__divider', this.time, { scaleX: 0, width: '0', opacity: 0, backgroundColor: '#141414' }, { width: '48%', scaleX: 1, opacity: 1, backgroundColor: '#ffffff', ease: this.ease })
      this.tl.staggerFromTo('.twob--intro__logo i', this.time, { y: '30px', opacity: 0 }, { y: '0', opacity: 1, ease: this.ease }, '0.05', '-=0.6')
      this.tl.fromTo('.twob--tagline', this.time, { y: '-30px', opacity: 0 }, { y: '0', opacity: 1, ease: this.ease }, '-=0.8')
      this.tl.fromTo('.twob--intro', 1.2, { y: '0', skewY: 0, scaleY: 1, opacity: 1 }, { y: '-100px', skewY: 3, scaleY: 1.15, opacity: 0, delay: 1.5, ease: this.ease, onComplete: () => { this.$emit('pageIn') } }, '-=0.8')
      this.tl.to('.twob--intro__wrapper', 0.01, { display: 'none', onComplete: () => { this.loaderOut = false } })
    }
  },
  mounted () {
    this.initLoader()
  }
}
</script>

<style lang="scss" scoped>
.twob--intro__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 10;
}
.twob--intro {
  opacity: 0;
}
.twob--intro__inner {
  overflow: hidden;
  h3 {
    font-size: 3.6vw;
    margin: 0;
    @include respond-to("tablet-portrait") { font-size: 2.6vw; }
    @include respond-to("desktop") { font-size: 1.4vw; }
  }
}
.twob--intro__divider {
  height: 1px;
  width: 0;
  margin: 20px auto;
  background-color: #141414;
  transform: translate3d(0,-50%,0);
}
.twob--intro__logo {
  font-family: $font-family-main;
  font-size: 8.45vw;
  margin-bottom: 2px;
  letter-spacing: .15rem;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  @include respond-to("tablet-portrait") { font-size: 5.4vw; }
  @include respond-to("desktop") { font-size: 3vw; }
  i {
    font-style: normal;
    display: inline-block;
  }
}
</style>
