export default {
  'loader': {
    'title': 'we make real websites'
  },
  'header': {
    title: {
      '1': 'Twob',
      '2': 'Projects',
      '3': 'Contact'
    },
    links: {
      '1': '/',
      '2': '/proyectos',
      '3': '/contacto'
    }
  },
  'home': {
    'title:1': 'We can change your digital world ',
    'title:2': 'bringing a fresh perspective to your brand. We make real websites.'
  },
  'homeWho': {
    'subtitle': 'Who we are',
    'txt:1': 'We’re a duo specialized in modern web design, development and user interfaces. We believe details are important, both the ones you can see and the ones you don’t. We like to think that our personal approach and how we work is the reason you’ll choose us.',
    'txt:2': 'Right now we’re giving the final touches to our new website, meanwhile, you can browse',
    'txt:3': 'our work',
    'txt:4': ' and services or simply ',
    'txt:5': 'contact us',
    'txt:6': ' and we can talk about your project.',
    'txt:7': 'We are website makers, we focus on giving a modern and daring image. We work for clients who want to leave behind mainstream, who are not afraid to evolve and are interested in differentiating themselves from their competition.',
    'txt:8': 'Our mission is to solve real problems for real people like you. Based in Barcelona and Buenos Aires, we work on all types of projects worldwide, both for consolidated brands and new initiatives. Size does not matter. 🙌🔥'
  },
  'homeWhat': {
    'subtitle': 'What we do',
    'txt': 'If you’re tired of reading the same companies descriptions over and over, who claim to be different but they’re actually not, That’s great! Us too! So let’s go straight to the part where we tell you how we can give you a hand.'
  },
  'contactFooter': {
    'subtitle': 'Contact us',
    'title:1': 'Start',
    'title:2': ' a project',
    'marquee': 'We know what you want. We know what you want. We know what you want.',
    'txt': 'Feel free to get in touch, we’re very friendly and always open to talk about new projects, opportunities or creative ideas to be part of your team.'
  },
  'contactForm': {
    'your:name': 'Your name *',
    'name:required': 'Your name is required',
    'your:brand': 'Brand name',
    'your:email': 'Email address *',
    'email:required': 'Email address is required',
    'email:valid': 'Please provide a valid email address',
    'your:phone': 'Phone number',
    'select:how': 'How did you find us?',
    'select:optionsWhere':
    {
      options:
      [
        'I did a web search',
        'I saw you on social media',
        'I read an article about you',
        'I got a recommendation',
        'Other'
      ]
    },
    'select:time': 'Estimated timeline',
    'select:optionsTimneline':
    {
      options:
      [
        '1-2 weeks',
        '1 month',
        '2 months',
        '3 months',
        '3 months +'
      ]
    },
    'select:budget': 'What is your budget?',
    'select:optionsBudget':
    {
      options:
      [
        'Don’t know yet',
        '800 - 1500€',
        '1500 - 3000€',
        '3000 - 5000€',
        '5000 - 10000€',
        '10000€ +'
      ]
    },
    'title:services': 'What can we help you with?',
    'service:dd': 'Web Design & Development',
    'your:message': 'Details of your project:',
    'submit:button': 'Share with us',
    'privacy:1': 'By clicking the button I agree with the collection and processing of my personal data as described in the',
    'privacy:2': 'Privacy policy'
  },
  'contactThanks': {
    'message:1': 'Progress made',
    'message:2': 'Your data was sent, we will contact you as soon as possible. Meanwhile, you can continue exploring our website.'
  },
  'contactError': {
    'message:1': 'Error submitting form',
    'message:2': 'Ups! Something failed, try again in a moment.'
  },
  'contactPage': {
    'subtitle': 'start a project',
    'title:1': 'You’ve ',
    'title:2': 'got an idea in mind?',
    'txt:1': 'We’re here to help you make it a reality, no matter how big or small, simple or complicated. We’ll find the right solutions for you and your business, and it will be our pleasure to be by your side throughout the whole process.',
    'txt:2': 'Take a few minutes to complete our project form so we can start thinking in advance. We’ll review it and contact you within one or two business days, responding your inquiries and steps to follow.',
    'footer': 'Prefer email or phone call?'
  },
  'cookieAdvice': {
    'txt:1': 'We use cookies to improve your browsing experience. If you continue to use our website you accept the ',
    'txt:2': 'cookies policy.',
    'txt:3': 'Yes, I like them!'
  },
  'footerBottom': {
    'txt:1': 'Full website coming soon'
  },
  'onBlur': {
    'title': '🤘 Come back soon.'
  },
  'onFocus': {
    'title': 'Twobrothers | Modern web design and development studio | Real Websites'
  },
  'routeText': {
    'title:1': 'Code with mind',
    'title:2': 'Design with soul',
    'title:3': 'Real websites',
    'title:4': 'Small legal advice',
    'title:5': 'Nothing here'
  },
  'orientationTxt': {
    'txt': 'Please use vertical orientation.'
  },
  'projects': {
    info: {
      '1': 'Design Development Nuxt js Prismic Netlify',
      '2': 'Design Development Nuxt js Prismic Netlify',
      '3': 'Design Development WP Theme Sage Bedrock Woocommerce',
      '4': 'Coming Soon WP Theme Sage Bedrock',
      '5': 'Design Development Ajax Portfolio SEO WP Custom',
      '6': 'Design Development Custom Wordpress E-commerce',
      '7': 'Design Development Wordpress Theme Portfolio',
      '8': 'Design Development Wordpress Theme Portfolio',
      '9': 'Wordpress Theme Portfolio'
    }
  },
  'services': {
    title: {
      '1': 'Web design',
      '2': 'Custom Static Websites',
      '3': 'Web development',
      '4': 'Theme Customization',
      '5': 'E-commerce',
      '6': 'Web apps',
      '7': 'Wordpress',
      '8': 'Hosting and SSL',
      '9': 'Squarespace',
      '10': 'SEO & Social',
      '11': 'Shopify',
      '12': 'Maintance & Support'
    },
    description: {
      '1': 'A well-designed website should convey the personality of your brand, product or service in a simple but complete way. Your users are usually impatient and probably also distracted. We will keep it simple and beautiful, fun and functional. A clean aesthetic backed by a solid concept is what we will always look for.',
      '2': 'A project based on a CMS like Wordpress is not always necessary or is the best solution. Sometimes, a personalized website is what best suits certain projects. We can help you determine the details within these scenarios.',
      '3': 'We are curious about new emerging technologies and continue to evolve. We make digital products through a human-centered approach. We not only believe in great ideas, but also in the perfect execution of them. We write smart code using the latest technology to create the best digital products.',
      '4': 'Do you need some changes to a template or theme within a CMS platform? We can help you analyze the possibilities to customize or add new features or functionalities to the current situation.',
      '5': 'Online stores can be built in different ways, using platforms such as WordPress, Shopify and Squarespace. At Twob we create stores of all sizes, form the simplest to the most complex, offering the best experience both for the end user and the website administrator.',
      '6': 'We provide next-gen solutions, we focus on developing custom web apps based on JavaScript with VueJs and Nuxt Js. With this new technology the code will not become obsolete and will be easy to develop in the future. It’s fast and fluid, you’ll have the same experience on all devices and you’ll be able to navigate offline..',
      '7': 'Whether for a landing page, a personal website, a blog or a portfolio, we’ll help you get the best of wordpress and use its features. We can also help you design and develop your online store using the WordPress + Woocommerce platform and customize its features based on the needs of your business.',
      '8': 'While there are many options available, we believe our hosting partner is the future of servers. We can get a little nerdy about it, but all that really matters is that your website will be super fast and reliable. The first year it will be free of charge, and we offer a free SSL certificate forever.',
      '9': 'Squarespace can be used for regular websites, online stores or blogs. We can integrate appointment reservations, social media accounts, calendars, videos, galleries or services such as Foursquare. The options are endless and your website can grow and change easily as your business does.',
      '10': 'We will provide you with the essential tools and help you get started with the tasks necessary to succeed in the SEO field. Depending on the platform on which the project is built, we can install various tools, and provide information to focus on your SEO and analytical needs.',
      '11': 'We design, develop, deploy and support small and large-scale e-commerce websites using Shopify. This includes new complete developments, platform migrations, as well as custom development projects.',
      '12': 'We offer services to keep your website up to date and running by monitoring updates, performance and speed. If you want additional support after launch or if something new emerges such as integrations, components or other things that might be out of your reach, we will be ready to intervene.'
    }
  },
  'errorPage': {
    'title': 'That page can’t be found.',
    'txt:1': 'It“s probably something we’ve messed up, or maybe you misspelt the URL? Either way you can',
    'txt:2': 'start over.'
  },
  'privacyPage': {
    'subtitle': 'your data',
    'title:1': 'Privacy ',
    'title:2': 'policy',
    'inner:title:1': 'Our commitment',
    'inner:txt:1': 'Confidentiality and security are our primary values and, consequently, we assume the commitment to guarantee the User’s privacy at all times and not to collect unnecessary information. Below, we provide all the necessary information about our Privacy Policy in relation to the personal data we collect.',
    'inner:title:2': '1.1 Responsibility',
    'inner:txt:2': 'Any person accessing this website assumes the role of user, committing to the observance and strict compliance with the provisions set forth herein, as well as any other legal provision that may apply.',
    'inner:txt:3': 'The provider disclaims any liability arising from the information published on its website and the lack of availability (falls) of the site which will also make periodic stops for technical maintenance. In addition, the provider reserves the right to modify any type of information that may appear on the website, without any obligation to pre-notify or inform users of said obligations, being understood as sufficient with the publication on the provider’s website.',
    'inner:title:3': '1.2 Use of cookies',
    'inner:txt:4': 'The provider’s website may use cookies (small text files that the server sends to the computer of the person accessing the page). It is a technique commonly used on the Internet to perform certain functions that are considered essential for the proper functioning and visualization of the site. The cookies used on the website have, in any case, a temporary nature with the sole purpose of making their subsequent transmission more efficient and disappear at the end of the user’s session. In no case will cookies be used to collect personal information.',
    'inner:txt:5': 'From the client’s website it is possible to be redirected to the contents of third-party websites.',
    'inner:txt:6': 'Since the provider cannot always control the content introduced by third parties on its websites, it does not assume any responsibility for such content. In any case, the provider undertakes the immediate withdrawal of any content that could contravene national or international legislation, morality or public order, proceeding to the immediate withdrawal of the redirection to said website, informing the authorities competent content in question.',
    'inner:txt:7': 'For more information about the cookie policy we use, you can check the cookie policy page by clicking here.',
    'inner:title:4': '1.3 Protection of personal data',
    'inner:txt:8': 'The provider complies with the Spanish regulations for the protection of personal data, and guarantees full compliance with the obligations set forth in Organic Law 15/1999, of December 13, on Protection of Personal Data (LOPD), the Royal Decree 1720/2007, of December 21, which approves the Regulations for the development of the LOPD and other regulations in force at all times, and ensures the correct use and treatment of the user’s personal data.',
    'inner:title:5': '1.4 Anti-spam policy',
    'inner:txt:9': 'The provider declares completely against the sending of unsolicited commercial communications and any type of conduct or manifestation known as “spam”, also declares itself committed to the fight against such abusive practices.',
    'inner:txt:10': 'Therefore, the provider guarantees the user that under no circumstances will the personal data collected on the website be transferred, shared, transferred, or sold to any third party, if their express consent.',
    'inner:txt:11': 'For the same reasons, the registration process should not be used to register email addresses of outsiders without the express consent of the affected persons.',
    'inner:txt:12': 'twobstudio.com implements as a countermeasure to this practice a double opt-in mailing list that needs explicit confirmation by the owner of the email account indicated as a subscription address, before receiving communications by email.',
    'inner:txt:13': 'In the event that a user still receives communications from this website without having registered, or without having given their express consent to said registration, they can cancel the subscription from the links provided in the communication itself.',
    'inner:txt:14': 'In addition, the user can contact us through the contact form that is maintained on the website, both to communicate what happened and to request the immediate removal of their data from our system.',
    'inner:title:6': '1.5 Data hosting',
    'inner:txt:15': 'For technical reasons and quality of service, the provision of the subscription service by email and sending newsletter is made from the premises of the company MailChimp (privacy policy). Company of American origin with facilities located in this same country adhering to the principles of “Safe Harbor”, in accordance with Commission Decision 2000/520 / EC of July 26, 2000 which makes them entities with an adequate level of protection for the purposes of the LOPD.',
    'inner:title:7': '1.6 Google analytics',
    'inner:txt:16': 'Google Analytics is a statistical data analysis service provided by Google (privacy policy). twobstudio.com uses this service to keep track of its usage statistics.',
    'inner:txt:17': 'Google Analytics uses cookies to help the website analyze statistical data on its use (number of total visits, most viewed pages, etc.). The information generated by the cookie (including your IP address) will be directly transmitted and archived by Google on servers in the United States.',
    'inner:txt:18': 'Google will use this information on our behalf in order to generate statistical information on the use of twobstudio.com, Google will not associate your IP address with any other data available to Google. Google may transmit such information to third parties when required by law, or when said third parties process the information on behalf of Google.',
    'inner:txt:19': 'You can refuse the processing of data or information by rejecting the use of cookies by selecting the appropriate configuration of your browser, however, doing so will limit the full functionality of twobstudio.com. By using this website, you consent to the processing of information by Google in the manner and for the purposes indicated above.',
    'inner:title:8': '1.7 Consent to the processing of personal data',
    'inner:txt:20': 'Within the framework of its activities, twobstudio.com has the possibility of registering users for sending email communications, making comments on the blog and sending messages through the contact form.',
    'inner:txt:21': 'The user through the acts of purchase process (if any), the subscription to the blog, the making of comments or the contact form will be giving his express consent to the treatment of the personnel provided according to the provisions of article 6 of the LOPD. The user may exercise their rights under the terms set forth in article 5 of the LOPD.',
    'inner:txt:22': 'These same acts also imply the express consent of the user to the international transfer of data that occurs in terms of the LOPD due to the physical location of the supplier facilities mentioned above.',
    'inner:txt:23': 'The personal data requested in these activities, will be incorporated into a file whose purpose is the communication of news related to the website of twobstudio.com acting as the provider responsible for the file. The fields marked with an asterisk are mandatory, and it is impossible to carry out the stated purpose if these data are not provided. You are also informed of the possibility of exercising the rights indicated in the section on User Rights.',
    'inner:title:9': '1.8 User rights',
    'inner:txt:24': 'In accordance with the provisions of article 5 of the LOPD, the user is informed that the exclusive purpose of the registration database is the sending of information on developments related to the website twobstudio.com as well as the formalization of the process of purchase (if any) and the communications derived from the same purchase process. Only the owners will have access to their data, and under no circumstances, these data will be transferred, shared, transferred, or sold to any third party.',
    'inner:txt:25': 'In accordance with the provisions of the LOPD, the user may at any time exercise their rights of access, rectification, cancellation, and opposition to the provider.',
    'inner:txt:26': 'To facilitate the exercise of these rights, a withdrawal request link is provided in all communications that will result in the immediate removal of the user’s personal data from our database.',
    'inner:title:10': '.9 Intellectual property and uses of the contents',
    'inner:txt:27': 'The twobstudio.com website, including but not limited to its programming, editing, compilation and other elements necessary for its operation, the designs, logos, text and / or graphics are the property of the provider or, where appropriate, have a license or express authorization by the authors.',
    'inner:txt:28': 'Any use not previously authorized by the provider will be considered a serious breach of the intellectual or industrial property rights of the author.',
    'inner:txt:29': 'The designs, logos, text and / or graphics outside the provider and that may appear on the website belong to their respective owners, being themselves responsible for any possible controversy that may arise regarding them. In any case, the provider has express and prior authorization from them.',
    'inner:txt:30': 'To make any kind of observation regarding possible breaches of intellectual or industrial property rights, as well as any of the contents of the website, you can do so through the contact form that is maintained on the website.',
    'inner:title:11': '1.10 Applicable law and jurisdiction',
    'inner:txt:31': 'For the resolution of all disputes or issues related to this website or the activities developed therein, Spanish legislation will apply, to which the parties expressly submit.'
  },
  'cookiesPage': {
    'subtitle': 'cookies and twob',
    'title:1': 'Cookies ',
    'title:2': 'policy',
    'inner:title:1': '1.1 What are cookies and why do we use them?',
    'inner:txt:1': 'A cookie is a file that is downloaded to the user’s device when accessing certain web pages to store and retrieve information about the navigation that is done from said device.',
    'inner:txt:2': 'Cookies allow said website, among other things, to store and retrieve information about the user’s decisions and habits. In twobstudio.com we use them to personalize the experience within the web, identify and remember the registered user and facilitate navigation.',
    'inner:txt:3': 'It is important to note that the use of cookies does not provide personal data of the user, which remains anonymous.',
    'inner:txt:4': 'The user can configure their browser to not accept the use of cookies, in which case the personalization of the experience would not apply, although they may continue to access the contents of our websites normally.',
    'inner:txt:5': 'On the Wikipedia page ',
    'inner:txt:6': 'it is possible to obtain more information about what with cookies and how they work',
    'inner:title:2': '1.2 What type of cookies our website uses',
    'inner:txt:7': 'Cookies, depending on their permanence, can be divided into session or permanent cookies. The first expire when the user closes the browser. The latter expire depending on when the objective for which they serve is fulfilled (for example, so that the user remains identified on the websites) or when they are manually deleted',
    'inner:txt:8': 'Additionally, depending on their objective, cookies can be classified as follows:',
    'inner:txt:9': 'Performance cookies: This type of cookie remembers your preferences for the tools found in the services, so you do not have to reconfigure the service every time you visit. As an example, this typology includes:',
    'inner:txt:10': 'Volume settings of video or sound players.',
    'inner:txt:11': 'The video transmission speeds that are compatible with your browser.',
    'inner:txt:12': 'Geolocation cookies: these cookies are used to find out what country you are in when a website or service is requested. This cookie is completely anonymous, and is only used to help orient the content to its location.',
    'inner:txt:13': 'Registration cookies: registration cookies are generated once the User has registered or subsequently opened his session, and they are used to identify him on the websites and services with the following objectives:',
    'inner:txt:14': 'Keep the user identified so that, if you close a service or web, the browser or computer and at another time or another day re-enter that service or web, you will continue to be identified, thus facilitating your navigation without having to identify yourself again. This functionality can be suppressed if the user presses the “Exit” functionality, so that this cookie is deleted and the next time he enters the web the user will have to log in to be identified.',
    'inner:txt:15': 'Check if the user is authorized to access certain services, for example, to participate in a contest.',
    'inner:txt:16': 'Additionally, some services may use third-party connectors such as Facebook, Twitter or Google. When the user registers in a service with credentials of a social network or third-party identification system, he authorizes it to keep a persistent cookie that remembers his identity and guarantees access to the web until it expires. The user can delete this cookie and revoke access to the web through social networks or third-party identification system by updating their preferences in the social network that you specify.',
    'inner:txt:17': 'Analytical cookies: Each time a User visits a Web or Service, a tool from an external provider (Google Analytics, comScore and the like) generates an analytical cookie on the user’s device. This cookie that is only generated during the visit, will serve in future visits to the services of twobstudio.com to identify the visitor anonymously. The main objectives pursued are:',
    'inner:txt:18': 'Allow anonymous identification of navigating users through the "cookie" (identifies browsers and devices, not people) and therefore the approximate counting of the number of visitors and their trend over time.',
    'inner:txt:19': 'Anonymously identify the most visited content and therefore more attractive to users.',
    'inner:txt:20': 'Know if the user who is accessing is new or repeats visit.',
    'inner:txt:21': 'Important: Unless the User decides to register, the cookie will never be associated with any personal data that can identify him. These cookies will only be used for statistical purposes that help to optimize the user experience on the site.',
    'inner:txt:22': 'How to disable cookies in the browser. Most browsers currently allow the user to configure whether they want to accept cookies and which of them. These settings are usually found in the ‘options’ or ‘Preferences’ of your browser menu.',
    'inner:txt:23': 'These are the instructions to configure cookies in the main browsers:',
    'inner:txt:24': 'Chrome: Settings -> Show advanced options -> Privacy -> Content settings. For more information, you can consult Google support or browser Help.',
    'inner:txt:25': 'Firefox: Tools -> Options -> Privacy -> History -> Custom Settings. For more information, you can consult Mozilla support or browser Help.',
    'inner:txt:26': 'Internet Explorer: Tools -> Internet Options -> Privacy -> Settings. For more information, you can consult Microsoft support or browser Help.',
    'inner:txt:27': 'Safari: Preferences -> Security. For more information, you can consult Apple support or browser Help.'
  },
  'marquee': {
    'txt': 'We make real websites. We make real websites. We make real websites.'
  },
  'seoHome': {
    'title': 'Twobrothers | Modern web design and development studio | Real Websites',
    'description': 'Twobrothers - We are a digital agency in Barcelona and Buenos Aires. A team of experts in modern web design and development, we can create your website, landing page, online store and much more.'
  },
  'seoProjects': {
    'title': 'Twobrothers | Discover our web design and development projects',
    'description': 'In our portfolio you can consult our most outstanding projects. Find examples of web design or web development'
  },
  'seoContact': {
    'title': 'Twobrothers | Contact our web design and development studio | Start your project',
    'description': 'Let’s talk, or even see you! Contact us to arrange a meeting, call us: +34 633 282 293'
  },
  'notFound': {
    'title': 'Twobrothers | Page not found',
    'description': 'Page not found'
  },
  language_picker_helper: 'Language',
  languages: {
    spanish: 'Es',
    english: 'En'
  }
}
