<template>
  <div class="projects">
    <app-projects></app-projects>
    <app-left-sidebar :midTitle="this.$t('routeText.title:2')"/>
    <div class="scroll--line">
      <div id="scrollOut"></div>
    </div>
  </div>
</template>

<script>
import Projects from '../components/Projects.vue'
import LeftSidebar from '../components/LeftSidebar.vue'

export default {
  name: 'Proyectos',
  components: {
    appProjects: Projects,
    appLeftSidebar: LeftSidebar
  },
  metaInfo () {
    return {
      title: this.$t('seoProjects.title'),
      meta: [
        { name: 'description', content: this.$t('seoProjects.description') }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.twobstudio.com/proyectos' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.projects {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
</style>
