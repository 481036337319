<template>
  <div class="twob--thankyou">
    <h4>{{ $t('contactThanks.message:1') }}</h4>
    <p>{{ $t('contactThanks.message:2') }}</p>
    <div class="thanks--image">
      <img :src="thanksImage">
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      thanksImage: 'https://media.giphy.com/media/S6qkS0ETvel6EZat45/giphy.gif'
    }
  }
}
</script>

<style lang="scss">
.twob--thankyou {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin-top: 14vw;
  padding-top: 12vw;
  padding-bottom: 12vw;
  @include respond-to("tablet-portrait") {
    margin-top: 4vw;
    padding-top: 9vw;
    padding-bottom: 9vw;
  }
  p { margin: 0; }
}
.thanks--image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0% 11%;
  @include respond-to("tablet-portrait") { padding: 4% 11%; }
  z-index: -1;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
    opacity: .2;
  }
}
</style>
