<template>
  <div class="main">
    <div class="o-scroll" ref="scrollSections" @scroll="scrolled(this)">
      <div class="section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-10">
            <h3>{{ $t('contactPage.subtitle') }}</h3>
            <h1>
              <span class="invisible--txt">{{ $t('contactPage.title:1') }}</span>{{ $t('contactPage.title:2') }}
            </h1>
          </div>
        </div>
      </div>
      <div class="middle--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-8 is-offset-2">
            <div class="footer--txt">
              <p>{{ $t('contactPage.txt:1') }}</p>
              <p class="no--margin__bottom">{{ $t('contactPage.txt:2') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="middle--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-8 is-offset-2">
            <div class="twob-form">
              <div class="form--wrapper scroll--animation" data-scroll>
                <transition name="formup" mode="out-in">
                  <component @scrollFixError="scrollFixError" @scrollChange="updateScroll" @onSuccess="onSuccess" :is="currentView"></component>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="trigger" class="middle--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-8 is-offset-2">
            <div class="contact--footer">
              <h4 class="title--footer__contact scroll--animation" data-scroll>{{ $t('contactPage.footer') }}</h4>
              <a
                class="dot footer--link scroll--animation"
                href="mailto:start@twobstudio.com"
                @mouseover="isOver"
                @mouseleave="isOut"
                data-scroll>start@twobstudio.com</a>
              <br>
              <a
                class="dot footer--link scroll--animation"
                href="tel:0034633282293"
                @mouseover="isOver"
                @mouseleave="isOut"
                data-scroll>+34 633 282 293</a>
            </div>
          </div>
        </div>
      </div>
      <app-footer/>
    </div>
    <app-left-sidebar :midTitle="this.$t('routeText.title:3')"/>
    <div class="scroll--line">
      <div id="scrollOut"></div>
    </div>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'
import { scrollMixin } from '../mixins/scrollMixin.js'
import { updateMixin } from '../mixins/updateMixin.js'
import { scrollerMixin } from '../mixins/scrollerMixin.js'
import LeftSidebar from '../components/LeftSidebar.vue'
import ContactForm from '../components/ContactForm.vue'
import ContactThanks from '../components/ContactThanks.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Contacto',
  components: {
    appLeftSidebar: LeftSidebar,
    appContactForm: ContactForm,
    appContactThanks: ContactThanks,
    appFooter: Footer
  },
  mixins: [
    cursorMixin,
    scrollMixin,
    updateMixin,
    scrollerMixin
  ],
  data () {
    return {
      currentView: 'app-contact-form'
    }
  },
  methods: {
    scrollFixError () {
      this.scrollTop('.twob-form', -200)
    },
    onSuccess () {
      this.scrollTop('.twob-form', -200)
      setTimeout(() => {
        this.currentView = 'app-contact-thanks'
      }, 610)
      setTimeout(() => {
        this.updateScroll(1000)
      }, 800)
    }
  },
  mounted () {
    this.updateScroll(1000)
  },
  metaInfo () {
    return {
      title: this.$t('seoContact.title'),
      meta: [
        { name: 'description', content: this.$t('seoContact.description') }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.twobstudio.com/contacto' }
      ]
    }
  }
}
</script>

<style lang="scss">
.twob-form {
  margin: 2vw 0 8vw;
}
.footer--link__contact {
  display: inline-block;
}
.form--wrapper {
  position: relative;
}
.contact--footer {
  text-align: center;
  margin-bottom: 8vw;
  margin-top: 12vw;
  @include respond-to("tablet-portrait") { font-size: 18px; margin-top: 2vw; }
  @include respond-to("desktop") { margin-top: 0; }
  a {
    display: inline-block;
    margin: 0 !important;
  }
}
.formup-enter,
.formup-leave-to {
  opacity: 0;
  transform: translateY(180px) skewY(3deg) scaleY(1.15);
}
.formup-enter-to,
.formup-leave {
  opacity: 1;
  transform: none;
}
.formup-enter-active,
.formup-leave-active {
  transition: all .6s ease-out;
  transition-delay: .4s;
}
</style>
