<template>
  <div class="orientation">
    <div class="orientation-inner">
      <i :style="orientationImage"></i>
      <span>{{ $t('orientationTxt.txt') }}</span>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      orientationImage: {
        backgroundImage: `url(${require('@/assets/img/rotate.svg')})`
      }
    }
  }
}
</script>

<style lang="scss">
.orientation {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0;
  background: #141414;
  z-index: 99999;
  text-align: center;
  display: none;
  @media screen and (max-width: 1023px) and (orientation: landscape) { display: block; }
}
.orientation-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  i {
    width: 50px;
    height: 50px;
    background: url(/content/themes/theme/assets/images/rotate.svg) no-repeat 50%/100%;
  }
  span {
    color: #fff;
    padding-top: 35px;
    font-size: 16px;
  }
}
</style>
