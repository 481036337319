import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import { i18n } from './plugins/i18n.js'
import VueScrollmagic from 'vue-scrollmagic'
import VueAnalytics from 'vue-analytics'
import Vuelidate from 'vuelidate'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueCookies from 'vue-cookies'
import Meta from 'vue-meta'
// import VueDrift from 'vue-drift'

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

export const eventActiveOn = new Vue()
export const eventActiveOff = new Vue()

Vue.mixin({
  data () {
    return {
      elem: document.querySelector('.cursor')
    }
  },
  beforeDestroy () {
    this.elem.classList.remove('active')
  }
})

Vue.use(VueScrollmagic)

Vue.use(VueAnalytics, {
  id: 'UA-71047656-1',
  router,
  debug: !isProd,
  sendHitTask: isProd
})

Vue.use(Vuelidate)

Vue.use(VueCookies)

Vue.use(Meta, {
  refreshOnceOnNavigation: true
})

/* Vue.use(VueDrift, {
  appId: 'ubbevf33uxvc'
}) */

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
