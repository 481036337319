<template>
  <div class="sidebar">
    <div class="sidebar--left"><h4>{{ midTitle }}</h4></div>
    <div class="sidebar--left__bottom">
      <h4><a
        :href="urlFb"
        target="_blank"
        @mouseover="isOver"
        @mouseleave="isOut">
        fb
      </a></h4>
      <h4><a
        :href="urlIg"
        target="_blank"
        @mouseover="isOver"
        @mouseleave="isOut">
        ig
      </a></h4>
    </div>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'

export default {
  mixins: [
    cursorMixin
  ],
  props: {
    midTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      urlFb: 'https://www.facebook.com/twobstudiobcnba/',
      urlIg: 'https://www.instagram.com/twobstudio/'
    }
  }
  /* computed: {
    midTitle () {
      if (this.$route.path === '/') {
        return this.$t('routeText.title:1')
      } else if (this.$route.path === '/proyectos') {
        return this.$t('routeText.title:2')
      } else if (this.$route.path === '/contacto') {
        return this.$t('routeText.title:3')
      } else if (this.$route.path === '/politica-de-privacidad') {
        return this.$t('routeText.title:4')
      } else if (this.$route.path === '/politica-de-privacidad') {
        return this.$t('routeText.title:4')
      } else {
        return this.$t('routeText.title:5')
      }
    }
  } */
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  left: 6.5vw;
  top: 0;
  width: 20px;
  height: 100vh;
  writing-mode: vertical-rl;
  z-index: 9;
  display: none;
  @include respond-to("tablet-portrait") { left: 5.8vw; display: block; }
  @include respond-to("desktop") { left: 5.5vw; }
  @include respond-to("desktop-medium") { left: 5.1vw; }
  @include respond-to("desktop-extra-large") { left: 4.8vw; }
}
.sidebar--left,
.sidebar--left__bottom {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  h4, a {
    display: inline-block;
    transform: rotate(180deg);
    color: rgba(255, 255, 255, 0.9);
    font-family: $font-family-main-bold;
    text-transform: uppercase;
    letter-spacing: .12em;
    font-size: 10px;
    margin: 0;
    user-select: none;
    @include respond-to("tablet-portrait") { font-size: 12px; }
    @include respond-to("desktop") { font-size: 14px; }
    @include respond-to("desktop-extra-large") { font-size: 16px; }
  }
}
.sidebar--left__bottom {
  top: auto;
  transform: none;
  bottom: 5.8vw;
  @include respond-to("desktop") { bottom: 3.8vw; }
  a { color: #808080; }
  a:hover { animation: color .3s infinite; }
  h4 {
    transform: rotate(0deg);
    &:last-of-type { margin-top: 6px; }
  }
}
</style>
