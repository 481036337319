<template>
  <div class="main">
    <div class="o-scroll" ref="scrollSections" @scroll="scrolled(this)">
      <div class="section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-10">
            <h3>twobrothers</h3>
            <h1>
              <span class="invisible--txt">{{ $t('home.title:1') }}</span><br>{{ $t('home.title:2') }}
            </h1>
          </div>
        </div>
      </div>
      <div class="center--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-10">
            <span class="subtitle scroll--animation" data-scroll>{{ $t('homeWho.subtitle') }}</span>
            <h5 class="scroll--animation" data-scroll>{{ $t('homeWho.txt:1') }}</h5>
            <h5 class="scroll--animation" data-scroll>{{ $t('homeWho.txt:2') }}
              <router-link
                to="/proyectos"
                class="inner--link"
                @mouseover.native="isOver"
                @mouseleave.native="isOut">
                <span class="link--txt">{{ $t('homeWho.txt:3') }}</span>
                <div class="marquee-wrapper small--link">
                  <span>{{ $t('marquee.txt') }}</span>
                  <span>{{ $t('marquee.txt') }}</span>
                </div>
              </router-link>{{ $t('homeWho.txt:4') }}<router-link
                to="/contacto"
                class="inner--link"
                @mouseover.native="isOver"
                @mouseleave.native="isOut">
                <span class="link--txt">{{ $t('homeWho.txt:5') }}</span>
                <div class="marquee-wrapper small--link">
                  <span>{{ $t('contactFooter.marquee') }}</span>
                  <span>{{ $t('contactFooter.marquee') }}</span>
                </div>
              </router-link>{{ $t('homeWho.txt:6') }}
            </h5>
          </div>
        </div>
      </div>
      <div class="center--section__fullwidth" data-scroll-section>
        <div class="twob--columns align--right">
          <div class="twob--column is-9">
            <div class="twob--img">
              <div class="twob--img__inner scroll--animation" data-scroll>
                <figure class="twob--img__bg" :style="cssImage"></figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center--section" data-scroll-section>
        <div class="twob--columns align--right">
          <div class="twob--column is-8">
            <div class="twob--txt">
              <p class="scroll--animation" data-scroll>{{ $t('homeWho.txt:7') }}</p>
              <p class="no--margin__bottom scroll--animation" data-scroll>{{ $t('homeWho.txt:8') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="center--section" data-scroll-section>
        <div class="twob--columns">
          <div class="twob--column is-10">
            <span class="subtitle scroll--animation" data-scroll>{{ $t('homeWhat.subtitle') }}</span>
            <h5 class="scroll--animation" data-scroll>{{ $t('homeWhat.txt') }}</h5>
          </div>
        </div>
        <div class="twob--columns">
          <div class="twob--column is-12">
            <service-accordion @scrollChange="updateScroll(500)"></service-accordion>
          </div>
        </div>
      </div>
      <app-contact-footer/>
      <app-footer/>
    </div>
    <app-left-sidebar :midTitle="this.$t('routeText.title:1')"/>
    <div class="scroll--line">
      <div id="scrollOut"></div>
    </div>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'
import { scrollMixin } from '../mixins/scrollMixin.js'
import { updateMixin } from '../mixins/updateMixin.js'
import { scrollerMixin } from '../mixins/scrollerMixin.js'
import LeftSidebar from '../components/LeftSidebar.vue'
import serviceAccordion from '../components/serviceAccordion.vue'
import ContactFooter from '../components/ContactFooter.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Twob',
  components: {
    serviceAccordion,
    appLeftSidebar: LeftSidebar,
    appContactFooter: ContactFooter,
    appFooter: Footer
  },
  mixins: [
    cursorMixin,
    scrollMixin,
    updateMixin,
    scrollerMixin
  ],
  data () {
    return {
      cssImage: {
        backgroundImage: `url(${require('@/assets/img/twob-bg.jpg')})`
      }
    }
  },
  mounted () {
    this.updateScroll(1000)
  },
  metaInfo () {
    return {
      title: this.$t('seoHome.title'),
      meta: [
        { name: 'description', content: this.$t('seoHome.description') }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.twobstudio.com/' }
      ]
    }
  }
}
</script>

<style lang="scss">
.subtitle {
  font-size: 10px;
  color: #808080;
  margin: 0;
  margin-bottom: 7vw;
  display: block;
  user-select: none;
  @include respond-to("tablet-portrait") { font-size: 12px; margin-bottom: 5.6vw; }
  @include respond-to("desktop") { font-size: 14px; margin-bottom: 3.6vw; }
  @include respond-to("desktop-medium") { font-size: 16px; }
  @include respond-to("desktop-extra-large") { font-size: 18px; }
}
.invisible--txt {
  color: rgba(255, 255, 255, 0.12);
}
.inner--link {
  position: relative;
  font-size: inherit;
  font-family: inherit;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  z-index: 2;
  .link--txt,
  .visible--link {
    display: block;
    transition: opacity .1s ease-out;
  }
  &:hover .link--txt,
  &:hover .visible--link { opacity: 0; }
  &:hover .marquee-wrapper { opacity: 1; }
}
.marquee-wrapper {
  animation: footer-line 18s infinite linear;
  display: block;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.01s ease-in;
  span {
    color: #e74c3c;
    display: inline-block;
    font-size: inherit;
    margin-right: 1.125rem;
    position: relative;
    background: linear-gradient(330deg, #e74c3c 0%, #f1c40f 20%, #1abc9c 40%, #3498db 60%, #9b59b6 80%, #e74c3c 100% );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include respond-to("desktop") { margin-right: 3.125rem; }
  }
}
.small--link span {
  margin-right: 0.4rem;
}
@keyframes footer-line {
  0% { transform: translateZ(0) }
  to { transform: translate3d(-50%,0,0) }
}
.twob--img {
  position: relative;
  padding-bottom: 60%;
  z-index: 1;
}
.twob--img__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.twob--img__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .16 !important;
  margin: 0;
  display: block;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  filter: grayscale(1);
  transition: filter 0.3s ease-in;
}
.twob--txt {
  padding-left: 0;
  @include respond-to("desktop") { padding-left: 2%; }
  @include respond-to("desktop-medium") { padding-left: 8%; }
}
.link--txt {
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 0.3rem);
    left: 0rem;
    width: 100%;
    height: 0.08rem;
    background: #f9f9f9;
    opacity: .3;
    @include respond-to("tablet-portrait") { top: calc(100% - 0.4rem); }
    @include respond-to("desktop-extra-large") { top: calc(100% - 0.7rem); }
  }
}
</style>
