import { eventActiveOn, eventActiveOff } from '../main'

export const cursorMixin = {
  methods: {
    isOver () {
      eventActiveOn.$emit('classAdded')
    },
    isOut () {
      eventActiveOff.$emit('classRemove')
    }
  }
}
