<template>
  <header class="header">
    <div class="header__logo">
      <router-link
        to="/"
        @mouseover.native="isOver"
        @mouseleave.native="isOut">
        <img class="header--logo__img white--logo" :src="logo"/>
        <img class="header--logo__img color--logo" :src="logoGif"/>
      </router-link>
    </div>
    <nav>
      <ul>
        <li v-for="{ title, link, key } in myList" :key="key">
          <router-link
            :to="link"
            class="dot"
            @mouseover.native="isOver"
            @mouseleave.native="isOut">{{ title }}</router-link>
        </li>
        <li>
          <languagePicker/>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'
import languagePicker from './languagePicker.vue'

export default {
  name: 'NavHeader',
  components: {
    languagePicker
  },
  mixins: [cursorMixin],
  data () {
    return {
      langs: ['es', 'en'],
      logo: require(`@/assets/img/header-logo.png`),
      logoGif: require(`@/assets/img/header-logo.gif`)
    }
  },
  computed: {
    myList () {
      return [
        { key: 1, title: this.$t('header.title.1'), link: this.$t('header.links.1') },
        { key: 2, title: this.$t('header.title.2'), link: this.$t('header.links.2') },
        { key: 3, title: this.$t('header.title.3'), link: this.$t('header.links.3') }
      ]
    }
  }
}
</script>

<style lang="scss">
header {
  position: fixed;
  z-index: 700;
  top: 2.5vw;
  right: 4vw;
  left: 4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(60px);
  transition: all .9s cubic-bezier(0.49, 0.03, 0.14, 0.97);
  ul {
  margin: 0;
  padding: 0;
    li {
      list-style: none;
      display: inline-block;
      &:not(:last-child) {
        margin-right: 10px;
        @include respond-to("tablet-portrait") { margin-right: 16px; }
      }
    }
  }
}
.is-loaded header {
  transform: none;
  opacity: 1;
  transition-delay: .03s;
}
nav {
  display: flex;
}
.dot {
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.49, 0.03, 0.14, 0.97);
  cursor: pointer;
  @include respond-to("tablet-portrait") { font-size: 14px; }
  @include respond-to("desktop") { font-size: 15px; }
  @include respond-to("desktop-extra-large") { font-size: 18px; }
  &:hover { animation: color .4s infinite; }
}
.header__logo a {
  position: relative;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  &:hover .color--logo { opacity: 1; }
  &:hover .white--logo {
    @include respond-to("desktop") { opacity: 0; }
  }
}
.header--logo__img {
  transition: opacity 0.3s cubic-bezier(0.49, 0.03, 0.14, 0.97);
  width: 75%;
  @include respond-to("tablet-portrait") { width: 90% }
  @include respond-to("desktop") { width: auto }
}
.color--logo {
  position: absolute;
  top: 3px;
  left: 0;
  opacity: 0;
  display: none;
  @include respond-to("desktop") { display: block; }
}
</style>
