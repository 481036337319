<template>
  <div
    class="dropdown"
    @click="languageMobile">
    <button
      class="dot"
      :class="{active: isLanguage}"
      @mouseover="isOver"
      @mouseleave="isOut">
      <span>{{ $i18n.locale }}</span>
      <span class="caret"></span>
    </button>
    <div class="dropdown-content" :class="{active: isLanguage}">
      <a
        class="dot"
        v-for="(lang, index) in languages"
        :key="`lang${index}`"
        @click="changeLanguage(lang)"
        @mouseover="isOver"
        @mouseleave="isOut">
      {{ lang.title }}
      </a>
    </div>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'

export default {
  name: 'languagePicker',
  mixins: [cursorMixin],
  data () {
    return {
      isLanguage: false
    }
  },
  computed: {
    languages () {
      return [
        {
          title: this.$t('languages.spanish'),
          value: 'es'
        },
        {
          title: this.$t('languages.english'),
          value: 'en'
        }
      ]
    }
  },
  methods: {
    changeLanguage (lang) {
      this.$i18n.locale = lang.value
      this.$store.dispatch('language/setLanguage', lang.value)
    },
    languageMobile () {
      this.isLanguage = !this.isLanguage
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  position: relative;
  button {
    position: relative;
    display: block;
    padding-right: 0;
    padding-left: 10px;
    width: 26px;
    background: transparent;
    border: 0;
    text-align: left;
    color: #fff;
    line-height: 1;
    text-transform: capitalize;
    appearance: none;
    @include respond-to("tablet-portrait") { padding-left: 16px; }
    &.active .caret {
      top: 12px;
      transform: rotate(135deg);
      @include respond-to("desktop") { top: 14px; }
    }
    &:focus {
      outline: 0;
    }
    &:before {
      content: "";
      width: 1px;
      height: 20px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.dropdown-content {
  position: absolute;
  background-color: #282828;
  width: auto;
  left: 1px;
  top: 28px;
  padding: 12px 15px;
  z-index: 1;
  opacity: 0;
  transform: translateY(60px) skewY(6deg) scaleY(1.2);
  transition: all .4s ease-out;
  &.active {
    opacity: 1;
    transform: none;
  }
}
.dropdown-content a {
  float: none;
  color: #fff;
  text-decoration: none;
  display: block;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
}
.caret {
  position: absolute;
  right: 0;
  top: 50%;
  display: block;
  width: 6px;
  height: 6px;
  margin-top: -7px;
  margin-left: 7px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: rotate(-45deg);
  transition: all .3s cubic-bezier(.6,0,.41,1);
  @include respond-to("tablet-portrait") { right: -5px; top: 60%; }
  @include respond-to("desktop") { right: -9px; }
}
</style>
