import LocomotiveScroll from 'locomotive-scroll'

export const scrollMixin = {
  methods: {
    initLocoScroll () {
      const _self = this
      this.scroll = new LocomotiveScroll({
        el: _self.$refs['scrollSections'],
        smooth: true,
        smoothMobile: false,
        inertia: 1
      })
    },
    updateScroll (time) {
      const _self = this
      setTimeout(() => {
        _self.scroll.update()
      }, time)
    },
    scrollTop (elem, num) {
      const _self = this
      _self.scroll.scrollTo(elem, num)
    },
    scrollDestroy () {
      const _self = this
      _self.scroll.destroy()
    }
  },
  mounted () {
    const _self = this
    this.$nextTick(() => {
      _self.initLocoScroll()
      setTimeout(() => {
        _self.scroll.update()
      }, 5000)
    })
  },
  destroyed () {
    this.scrollDestroy()
  }
}
