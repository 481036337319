<template>
  <div
    :class="{ hide : isCookieHide }"
    class="cookie--policy">
    <div class="twob--columns">
      <div class="twob--column is-12">
        <div class="cookie--txt">
          <span>{{ $t('cookieAdvice.txt:1') }}<router-link to="/politica-de-cookies" class="cookie--link color--action" @mouseover.native="isOver" @mouseleave.native="isOut">{{ $t('cookieAdvice.txt:2') }}</router-link></span>
          <button @click="cookieLeave" @mouseover="isOver" @mouseleave="isOut">{{ $t('cookieAdvice.txt:3') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cursorMixin } from '../mixins/cursorMixin.js'
import { TweenMax, Power2 } from 'gsap'

export default {
  mixins: [
    cursorMixin
  ],
  data () {
    return {
      isCookieHide: this.$cookies.isKey('cookieAdvice')
    }
  },
  methods: {
    cookieLeave () {
      this.$cookies.set('cookieAdvice', true, '7d')
      let elem = document.querySelector('.cookie--policy')
      TweenMax.to(elem, 0.8, {
        y: 180,
        skewY: 3,
        skewX: 0,
        scaleY: 1.15,
        opacity: 0,
        ease: Power2.easeIn,
        onComplete: () => { elem.classList.add('hide') }
      })
    }
  }
}
</script>

<style lang="scss">
.cookie--policy {
  position: fixed;
  text-align: left;
  padding: 2rem;
  background-color: #000;
  width: initial;
  bottom: 10px;
  right: 10px;
  left: 10px;
  z-index: 101;
  box-sizing: border-box;
  img { width: 100px; }
  @include respond-to("tablet-portrait") {
    width: 300px;
    left: 10px;
    right: auto;
  }
  &.hide {
    display: none;
  }
}
.cookie--txt {
  padding: 0 4px;
  span {
    font-size: 14px;
    color: #fff;
    line-height: 1.6;
    letter-spacing: 0;
  }
  .cookie--link {
    text-decoration: underline;
    display: inline-block;
  }
  button {
    position: relative;
    background-color: transparent;
    border: 0;
    color: #fff;
    font-family: $font-family-main;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .04em;
    font-size: 23px;
    display: block;
    margin: 28px 0 0;
    padding: 0;
    &:focus { outline: 0;  }
    &:after {
      content: '';
      height: 38px;
      width: 38px;
      background-image: url('../assets/img/cookies-icon.svg');
      background-size: cover;
      display: block;
      position: absolute;
      top: 40%;
      right: -60%;
      transform: translateY(-50%);
    }
  }
}
</style>
